import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { faComments } from "@fortawesome/pro-regular-svg-icons";
import Sidebar from "../Sidebar/Sidebar";
import Funker530Comments from "funker530comments";
import ArticleHeader from "./ArticleComponents/ArticleHeader";
import ArticleBody from "./ArticleComponents/ArticleBody";
import { useParams, useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../../Context";
import LoadingIndicator from "../../../../LoadingIndicator";

function Article(props) {
  const {
    IsAdmin,
    AssignSEOData,
    loggedInUser,
    darkMode,
    GetArticles,
    ScrollToTop,
  } = useSiteContext();

  const { slug } = useParams();
  const [article, SetArticle] = useState({ id: 0 });
  const [selectedTab, SetSelectedTab] = useState("related");
  const [sidebarListHeight, SetSidebarListHeight] = useState(0);
  const sidebarListElement = useRef(null);

  const navigate = useNavigate();

  function SelectedTab(tab) {
    SetSelectedTab(tab);
  }

  async function GetArticle() {
    ScrollToTop();
    if (props.previewMode) {
      SetArticle(props.previewArticle);
      return;
    }
    SetArticle({ id: 0 });

    let articleList = await GetArticles([{ key: "slug", value: slug }]);
    if (articleList.length === 0) {
      alert("Article not found");
      navigate("/articles");
      return;
    }
    SetArticle(articleList[0]);
  }

  function CalculateListHeight() {
    if (sidebarListElement.current == null) return;
    SetSidebarListHeight(sidebarListElement.current.offsetHeight);
  }

  useEffect(() => {
    if (article != null && article.id > 0) {
      AssignSEOData(
        article.title,
        article.summary,
        article.image,
        article.keyphrases,
        article.author
      );
      SetSidebarListHeight(10);
      sidebarListElement.current =
        document.getElementById("sidebarArticleList");
      setTimeout(() => {
        CalculateListHeight();
      }, 100);
    }
  }, [article]);

  useEffect(() => {
    GetArticle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <>
      <div
        className={
          !props.previewMode ? "mainContent article" : "previewContainer"
        }
      >
        {IsAdmin() ? (
          <div
            className="editArticleButtonContainer"
            onClick={() => navigate("/admin/" + article.id)}
          >
            <FontAwesomeIcon className="editArticleButton" icon={faPen} />
          </div>
        ) : null}
        <div className="mainContentSections article">
          <div className="mainContentBox article">
            {article.slug != null ? (
              <>
                <ArticleHeader article={article} />
                <ArticleBody article={article} />
              </>
            ) : (
              <LoadingIndicator />
            )}
          </div>
        </div>
        <div className="mobileTabs">
          <div
            className={
              selectedTab === "comments" ? "mobileTab selected" : "mobileTab"
            }
            onClick={() => SelectedTab("comments")}
          >
            <FontAwesomeIcon icon={faComments} className="tabsIcon comments" />
            Comments
          </div>
          <div
            className={
              selectedTab === "related" ? "mobileTab selected" : "mobileTab"
            }
            onClick={() => SelectedTab("related")}
          >
            <FontAwesomeIcon icon={faGrid2} className="tabsIcon related" />
            Related
          </div>
        </div>
        {props.previewMode ? null : (
          <Sidebar
            bannerAdCount={1}
            boxAdCount={1}
            articleCount={4}
            attachedArticleId={article.id}
            minimumArticleCount={3}
            selectedTab={selectedTab}
            sidebarListHeight={sidebarListHeight}
          />
        )}
      </div>
      {props.previewMode ? null : (
        <div
          className={
            selectedTab === "comments"
              ? "commentsContainer selected"
              : "commentsContainer notSelected"
          }
        >
          {article.id >= 1 ? (
            loggedInUser != null ? (
              <Funker530Comments
                articleId={"B" + article.id}
                // articleId={6381}
                userId={loggedInUser.id}
                userEmail={loggedInUser.email}
                username={loggedInUser.username}
                superAdmin={loggedInUser.super}
                groups={loggedInUser.groups}
                darkMode={darkMode}
              />
            ) : (
              <Funker530Comments articleId={"B" + article.id} />
            )
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
}

export default Article;

import React from "react";

function LoadingIndicator() {
  return (
    <div className="loadingIndicator">
      <div className="loadingAnimation">
        <div className="loadingAnimationInner">+</div>
      </div>
      Loading...
    </div>
  );
}

export default LoadingIndicator;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClock } from "@fortawesome/pro-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import * as Constants from "../../../Constants";
import { useSiteContext } from "../../../Context";

function Search() {
  const [search, SetSearch] = useState("");
  const [searchModeMobile, SetSearchModeMobile] = useState(false);
  const navigate = useNavigate();
  const { ScrollToTop } = useSiteContext();

  function handleSearchChange(e) {
    SetSearch(e.target.value);
  }

  function ClickSearch() {
    SetSearch("");
    ScrollToTop();
    navigate("/articles?search=" + encodeURIComponent(search));
  }

  function SearchModeMobile() {
    SetSearchModeMobile(!searchModeMobile);
  }

  function MobileClickSearch() {
    if (search === "") {
      SetSearchModeMobile(!searchModeMobile);
      return;
    }
    SetSearch("");
    SetSearchModeMobile(!searchModeMobile);
    ScrollToTop();
    navigate("/articles?search=" + encodeURIComponent(search));
  }

  return (
    <div
      className={
        window.screen.width > 1285
          ? "headerItemContainer search"
          : "headerItemContainer search mobile"
      }
    >
      <Link
        to={Constants.Main_Site_URL + "latest/"}
        target="_self"
        className="headerContentItem latest showOnMobile"
      >
        <FontAwesomeIcon icon={faClock} className="headerIcon clock" />
      </Link>
      <input
        className={
          window.screen.width > 1285
            ? "searchInput"
            : searchModeMobile
            ? "searchInput"
            : "searchInput hiddenOnMobile mobile"
        }
        placeholder="Search"
        value={search}
        onChange={handleSearchChange}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            if (window.screen.width > 1258) ClickSearch();
            else MobileClickSearch();
          }
        }}
      />
      <div
        className="searchIconContainer"
        onClick={
          window.screen.width > 1258
            ? ClickSearch
            : searchModeMobile
            ? MobileClickSearch
            : SearchModeMobile
        }
      >
        <FontAwesomeIcon className="headerIcon search" icon={faSearch} />
      </div>
    </div>
  );
}

export default Search;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Categories from "./Categories/Categories";
import Trending from "./Trending/Trending";
import Latest from "./Latest/Latest";
import Search from "./Search/Search";
import Avatar from "./Avatar/Avatar";
import FunkerPremium from "./FunkerPremium/FunkerPremium";
import HeaderSidebar from "./HeaderSidebar/HeaderSidebar";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../Context";

function Header(props) {
  const [showSidebar, SetShowSidebar] = useState(false);
  const [sidebarSlidingOut, SetSidebarSlidingOut] = useState(false);
  const { IsPremium, ScrollToTop } = useSiteContext();

  const navigate = useNavigate();

  function ShowSidebar() {
    SetShowSidebar(true);
    SetSidebarSlidingOut(false);
  }

  function HideSidebar() {
    SetSidebarSlidingOut(true);
  }

  useEffect(() => {
    if (sidebarSlidingOut) {
      setTimeout(() => {
        SetShowSidebar(false);
        SetSidebarSlidingOut(false);
      }, 500);
    }
  }, [sidebarSlidingOut]);

  return (
    <>
      <div id="headerContainer" className="headerContainer">
        <div className="headerLeft">
          <div className="headerItem icon">
            <FontAwesomeIcon
              className="headerIcon bars"
              icon={faBars}
              onClick={ShowSidebar}
            />
          </div>
          <div className="headerItem">
            <img
              className="headerLogo"
              src="/blogLogo.png"
              onClick={() => {
                ScrollToTop();
                navigate("/");
              }}
              alt=""
            />
          </div>
          <Categories />
          <Trending />
          <Latest />
          {IsPremium() ? null : <FunkerPremium />}
        </div>
        <div className="headerRight">
          <Search />
          <Avatar />
        </div>
      </div>
      {showSidebar ? (
        <HeaderSidebar
          sidebarSlidingOut={sidebarSlidingOut}
          CloseSidebar={HideSidebar}
        />
      ) : null}
    </>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import MostRecent from "./MostRecent/MostRecent";
import { useSiteContext } from "../../../../Context";
import { useNavigate } from "react-router-dom";

function Featured() {
  const [featuredArticle, SetFeaturedArticle] = useState(null);
  const { GetArticles, MakeParameterItem, SetSEODataToDefault, ScrollToTop } =
    useSiteContext();
  const [shortenedBody, SetShortenedBody] = useState("");
  const [sidebarListHeight, SetSidebarListHeight] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (featuredArticle != null) {
      let shortenedBody = featuredArticle.body;
      if (featuredArticle.body.length > 1500) {
        shortenedBody = featuredArticle.body.substring(0, 1500);
        if (featuredArticle.body[1501] != " ") {
          shortenedBody = shortenedBody.substring(
            0,
            shortenedBody.lastIndexOf(" ")
          );
        }
        shortenedBody += "...";
      }
      SetShortenedBody(shortenedBody);
    }
  }, [featuredArticle]);

  useEffect(() => {
    if (shortenedBody != "") {
      setTimeout(() => {
        SetSidebarListHeight(
          document.getElementById("sidebarArticleList").offsetHeight
        );
      }, 200);
    }
  }, [shortenedBody]);

  useEffect(() => {
    let params = [];
    SetSEODataToDefault();
    params.push(MakeParameterItem("featured", true));
    GetArticles(params).then((response) => {
      SetFeaturedArticle(response[0]);
    });
  }, []);

  return (
    <div className="mainContent featured">
      <div className="mainContentFeaturedContent">
        <div className="mainContentBox featured">
          <div className="mainContentHeader featured">
            <div className="mainContentHeaderTitle featured">
              Featured Article:{" "}
              {featuredArticle != null ? featuredArticle.title : ""}
            </div>
          </div>
          <div className="mainContentBody featured">
            <div className="featuredArticleImage">
              <img src={featuredArticle != null ? featuredArticle.image : ""} />
            </div>
            <div className="featuredArticleText">
              {featuredArticle != null ? (
                <div
                  className="mainContentText article"
                  dangerouslySetInnerHTML={{
                    __html: shortenedBody,
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="mainContentFooter featured"
            onClick={() => {
              ScrollToTop();
              navigate("/article/" + featuredArticle.slug);
            }}
          >
            <div className="mainContentFooterText featured">
              READ FULL ARTICLE
            </div>
          </div>
        </div>
        <Sidebar
          bannerAdCount={1}
          articleCount={4}
          sidebarListHeight={sidebarListHeight}
        />
      </div>
      <MostRecent />
    </div>
  );
}

export default Featured;

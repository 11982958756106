import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import axios from "axios";
import * as Constants from "../../../Constants";

function AdminDelete(props) {
  function DeleteComment() {
    axios({
      method: "post",
      url: Constants.COMMENTS_API_URL + "DeleteComment",
      params: {
        articleId: props.articleId,
        commentGuid: props.id,
        field: "",
        value: "true",
        userId: props.adminUserId,
        userEmail: props.adminUserEmail,
      },
    })
      .then((response) => {
        alert("Comment has been deleted!");
        props.FullRefresh(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faXmark}
        id={props.id + "AdminDelete"}
        className="commentAdminDelete"
        data-tooltip-id="commentTooltip"
        data-tooltip-content="Delete Comment"
        onClick={DeleteComment}
      />
    </>
  );
}

export default AdminDelete;

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import * as Constants from "./Constants";
import Cookies from "universal-cookie";
import { faFaceSleeping } from "@fortawesome/pro-solid-svg-icons";

const CurrentContext = React.createContext();

function Context({ children }) {
  const defaultSEOData = GenerateSEOData(
    "Funker530",
    "Funker530: Combat footage and military news for the veteran community.",
    "https://images.funker530.com/images/media/logo.png",
    "Funker Military Videos Articles News Combat Footage Reviews",
    "Funker530"
  );

  const cookies = new Cookies();
  const [loggedInUser, SetLoggedInUser] = useState(null);
  const [checkedForLogin, SetCheckedForLogin] = useState(false);
  const [sessionToken, SetSessionToken] = useState(cookies.get("fmsst"));
  const [videoCategories, SetVideoCategories] = useState([]);
  const [articleCategories, SetArticleCategories] = useState([]);
  const [authors, SetAuthors] = useState([]);
  const [SEOData, SetSEOData] = useState(defaultSEOData);
  const [darkMode, SetDarkMode] = useState(true);
  const darkModeValue = darkMode ? "dark" : "light";

  function AssignSEOData(title, description, image, keywords, author) {
    SetSEOData(GenerateSEOData(title, description, image, keywords, author));
  }

  function SetSEODataToDefault() {
    SetSEOData(defaultSEOData);
  }

  function GenerateSEOData(title, description, image, keywords, author) {
    return {
      title: title,
      description: description,
      keywords: keywords,
      author: author,
      twitterCard: "summary_large_image",
      twitterSite: "@funkeractual",
      twitterCreator: "@funkeractual",
      twitterTitle: title,
      twitterDescription: description,
      twitterImage: image,
      ogtype: "website",
      ogurl: window.location.href,
      ogtitle: title,
      ogdescription: description,
      ogimage: image,
    };
  }

  function ScrollToTop() {
    let headerContainer = document.getElementById("headerContainer");
    if (headerContainer != null) {
      headerContainer.scrollIntoView();
    }
  }

  function DarkModeToggle(isOn) {
    let expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + 3000 * 24 * 60 * 60 * 1000
    );
    let domain = ".funker530.com";
    if (window.location.host.includes("127.0.0.1")) {
      domain = "127.0.0.1";
    }
    cookies.set("mode", isOn, {
      path: "/",
      expires: expirationDate,
      domain: domain,
    });
    SetDarkMode(isOn);
  }

  function GetLoggedInUser() {
    var formData = new FormData();
    formData.append("token", sessionToken);
    axios({
      method: "post",
      url: Constants.API_URL + "GetUserInfo" + Constants.API_KEY,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        if (response.data.id != null) {
          SetLoggedInUser(response.data);
          SetCheckedForLogin(true);
        } else {
          if (response.data === "Login Not Found") {
            cookies.remove("fmsst");
          }
          let baseurl = "https://funker530.com";
          let path = "/articles/";
          if (
            window.location.host.includes("127.0.0.1") ||
            window.location.host.includes("localhost")
          ) {
            baseurl = "http://127.0.0.1:8000";
          }
          if (window.location.href.includes("/article/")) {
            path = window.location.pathname;
          }
          RedirectUser(baseurl + path);
        }
      })
      .catch((error) => {
        SetCheckedForLogin(true);
      });
  }

  function GetCategories(type) {
    axios({
      method: "get",
      url: Constants.API_URL + "GetCategories",
      params: {
        code: Constants.API_KEY_ONLY,
        type: type,
      },
    })
      .then((response) => {
        if (type === "blog") {
          SetArticleCategories(response.data);
        }
        if (type === "video") {
          SetVideoCategories(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetAuthors() {
    axios({
      method: "get",
      url: Constants.API_URL + "GetAuthors",
      params: {
        code: Constants.API_KEY_ONLY,
      },
    })
      .then((response) => {
        SetAuthors(response.data.sort((a) => a.name));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function IsPremium() {
    if (
      loggedInUser != null &&
      loggedInUser.premiumEndDate != null &&
      (new Date(loggedInUser.premiumEndDate) >= new Date() ||
        loggedInUser.isPremium)
    ) {
      return true;
    }
    return false;
  }

  function IsAdmin() {
    if (loggedInUser != null) {
      if (loggedInUser.groups.length > 0 || loggedInUser.super) {
        return true;
      }
    }
    return false;
  }

  function RedirectUser(url) {
    window.location.href = url;
    return null;
  }

  function MakeDropdownItem(icon, label, navigationPath, type) {
    return {
      icon: icon,
      label: label,
      navigationPath: navigationPath,
      type: type,
    };
  }

  function MakeParameterItem(key, value) {
    return {
      key: key,
      value: value,
    };
  }

  async function GetArticles(params) {
    if (params == null) {
      params = [];
    }
    let processedParams = new URLSearchParams();
    let headers = {};
    let responseArray = [];
    processedParams.append("code", Constants.API_KEY_ONLY);
    if ((await IsAdmin()) && params["random"] == null) {
      headers = { userId: loggedInUser.id, email: loggedInUser.email };
    }
    params.forEach((param) => {
      processedParams.append(param.key, param.value);
    });

    await axios({
      method: "get",
      url: Constants.API_URL + "GetArticles",
      params: processedParams,
      headers: headers,
    })
      .then((response) => {
        if (Array.isArray(response.data)) {
          responseArray = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return responseArray;
  }

  async function GetAds(bannerCount, boxCount) {
    let params = new URLSearchParams();
    let responseArray = [];
    params.append("code", Constants.API_KEY_AD_ONLY);
    params.append(
      "jsonRequest",
      '[{"typeId":2,"amount":' +
        bannerCount +
        '},{"typeId":3,"amount":' +
        boxCount +
        "}]"
    );
    await axios({
      method: "get",
      url: Constants.API_URL_AD + "GetAds",
      headers: { "Content-Type": "application/json" },
      params: params,
    })
      .then((response) => {
        if (Array.isArray(response.data)) {
          responseArray = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return responseArray;
  }

  async function GetRandomArticles(params = []) {
    let processedParams = params;
    let responseArray = [];
    processedParams.push(MakeParameterItem("random", "true"));
    await GetArticles(processedParams).then((response) => {
      if (Array.isArray(response)) {
        responseArray = response;
      }
    });
    return responseArray;
  }

  async function RefreshBackendData() {
    GetCategories("video");
    GetCategories("blog");
    GetAuthors();
  }

  useEffect(() => {
    SetSessionToken(cookies.get("fmsst"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.get("fmsst")]);

  useEffect(() => {
    if (sessionToken != null && sessionToken !== "") {
      GetLoggedInUser();
    } else {
      SetCheckedForLogin(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionToken]);

  useEffect(() => {
    RefreshBackendData();
  }, []);

  useEffect(() => {
    document
      .querySelector("body")
      .setAttribute("data-funkerTheme", darkModeValue);
  }, [darkMode]);

  useEffect(() => {
    let darkModeCookie = cookies.get("mode");
    if (darkModeCookie != null && darkModeCookie == "false") {
      SetDarkMode(false);
    }
  }, []);

  return (
    <CurrentContext.Provider
      value={{
        loggedInUser,
        SetLoggedInUser,
        checkedForLogin,
        IsPremium,
        videoCategories,
        articleCategories,
        authors,
        RefreshBackendData,
        IsAdmin,
        RedirectUser,
        MakeDropdownItem,
        MakeParameterItem,
        GetArticles,
        GetAds,
        GetRandomArticles,
        SEOData,
        AssignSEOData,
        SetSEODataToDefault,
        darkMode,
        DarkModeToggle,
        ScrollToTop,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
}

export function useSiteContext() {
  const context = useContext(CurrentContext);
  if (!context) {
    throw new Error("useSiteContext must be used within a Context");
  }
  return context;
}

export default Context;

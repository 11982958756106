import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectanglePro } from "@fortawesome/pro-solid-svg-icons";

function FunkerPremium(props) {
  function HandleNavigation() {
    window.location.href =
      "https://funker530.com/funker530_premium/?next=" + window.location.href;
  }

  return (
    <div className="headerItemContainer funkerPremium hiddenOnMobile">
      <div className="headerItemContainerItem funkerPremium">
        <FontAwesomeIcon className="headerIcon pro" icon={faRectanglePro} />
        <div className="headerItemText" onClick={HandleNavigation}>
          FUNKER530 PREMIUM
        </div>
      </div>
    </div>
  );
}

export default FunkerPremium;

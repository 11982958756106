import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ArticlesSection from "./ArticlesSection/ArticlesSection";
import Featured from "./Featured/Featured";
import Article from "./Article/Article";
import AddEditArticle from "./AddEditArticle/AddEditArticle";
import * as Constants from "../../../Constants";
import axios from "axios";
import LoadingIndicator from "../../../LoadingIndicator";
import { useSiteContext } from "../../../Context";

function MainContent() {
  const { checkedForLogin } = useSiteContext();

  return (
    <div className="mainContentContainer">
      {checkedForLogin ? (
        <Routes>
          <Route path="/" element={<Featured />} />
          <Route path="/articles" element={<ArticlesSection />} />
          <Route
            path="/article/:slug"
            element={<Article previewMode={false} />}
          />
          <Route path="/admin/:id" element={<AddEditArticle />} />
          <Route path="/admin" element={<AddEditArticle />} />
        </Routes>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
}

export default MainContent;

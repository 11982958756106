import React from "react";

function TableOfContentsItem(props) {
  return (
    <>
      {" "}
      {props.header != null ? (
        <div className="tableOfContentsItem" id={"ToC" + props.header.id}>
          <div
            className="tableOfContentsItemText"
            onClick={() => props.header.element.scrollIntoView(true)}
          >
            {props.header.text}
          </div>
          {props.header.children != null
            ? props.header.children.map((c) => (
                <TableOfContentsItem key={c.id} text={c.text} header={c} />
              ))
            : null}
        </div>
      ) : null}
    </>
  );
}

export default TableOfContentsItem;

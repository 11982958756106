import React, { useEffect, useState } from "react";
import AddEditSidebar from "./AddEditSidebar";
import { useSiteContext } from "../../../../Context";
import * as Constants from "../../../../Constants";
import { Editor } from "@tinymce/tinymce-react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTag,
  faPenNib,
  faSquareShareNodes,
} from "@fortawesome/pro-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";
import Article from "../Article/Article";
import { BlobServiceClient } from "@azure/storage-blob";

function AddEditArticle(props) {
  const [article, SetArticle] = useState(null);
  const [author, SetAuthor] = useState("");
  const [title, SetTitle] = useState("");
  const [body, SetBody] = useState("");
  const [plainTextBody, SetPlainTextBody] = useState("");
  const [image, SetImage] = useState("");
  const [imageToUpload, SetImageToUpload] = useState(null);
  const [slug, SetSlug] = useState("");
  const [hidden, SetHidden] = useState(false);
  const [imageCaption, SetImageCaption] = useState("");
  const [featuredDate, SetFeaturedDate] = useState(null);
  const [publishDate, SetPublishDate] = useState(null);
  const [summary, SetSummary] = useState("");
  const [autoSummary, SetAutoSummary] = useState("");
  const [featured, SetFeatured] = useState(false);
  const [categories, SetCategories] = useState([]);
  const { loggedInUser, darkMode } = useSiteContext();
  const [showPreview, SetShowPreview] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { ScrollToTop, articleCategories, GetArticles } = useSiteContext();
  const [previewArticle, SetPreviewArticle] = useState({});
  const blobServiceClient = new BlobServiceClient(
    Constants.BLOB_STORAGE_URL,
    null
  );
  // const containerName = "images";
  // const blobName = "funkerimages";
  // const timestamp = new Date.now();
  // const fileName = "funkerimages" + timestamp + ".jpg";
  // const containerClient = blobServiceClient.getContainerClient(containerName);

  function GeneratePreviewArticle() {
    SetPreviewArticle({
      id: 0,
      author: author,
      title: title,
      body: body,
      plainTextBody: plainTextBody,
      image: image,
      slug: slug,
      hidden: hidden,
      imageCaption: imageCaption,
      featuredDate: featuredDate,
      publishDate: publishDate,
      summary: summary,
      featured: featured,
      categories: articleCategories.filter((c) => categories.includes(c.id)),
    });
  }

  function ChangeTitle(e) {
    SetTitle(e.target.value);
  }

  function ChangeImage(e) {
    SetImage(e.target.value);
  }

  function ChangeImageToUpload(e) {
    console.log(e.target.files[0].name);
    SetImageToUpload(e.target.files[0]);
  }

  function ChangeSummary(e) {
    SetSummary(e.target.value);
  }

  function ChangeAuthor(e) {
    SetAuthor(e.target.value);
  }

  function ChangePublishDate(e) {
    SetPublishDate(e.target.value);
  }

  function ChangeFeatured(e) {
    SetFeatured(e.target.checked);
  }

  function ChangeHidden(e) {
    SetHidden(e.target.checked);
  }

  function ChangeSlug(e) {
    SetSlug(e.target.value);
  }

  function ChangeImageCaption(e) {
    SetImageCaption(e.target.value);
  }

  function ChangeFeaturedDate(e) {
    SetFeaturedDate(e.target.value);
  }

  function ChangeCategories(categoryId) {
    if (categories.includes(categoryId)) {
      SetCategories(categories.filter((c) => c !== categoryId));
    } else {
      SetCategories([...categories, categoryId]);
    }
  }

  function ShowPreview() {
    SetShowPreview(!showPreview);
  }

  function Submit() {
    if (article == null) {
      CreateArticle();
    } else {
      UpdateArticle();
    }
  }

  function SetEditorColor() {
    let backgroundColor = "";
    if (darkMode) {
      backgroundColor = "body { for background-color: #f2f2f2; }";
    }
    return backgroundColor;
  }

  function CreateArticle() {
    var formData = new FormData();
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("body", body);
    formData.append("plainTextBody", plainTextBody);
    formData.append("publishDate", publishDate);
    formData.append("author", author);
    formData.append("hidden", hidden);
    formData.append("image", image);
    formData.append("imageCaption", imageCaption);
    formData.append("featured", featured);
    formData.append("featuredDate", featuredDate);
    formData.append("summary", summary);
    if (categories.length <= 0) {
      alert("Please select at least one category");
      return;
    }
    formData.append("categories", categories.join(","));
    axios({
      method: "post",
      url: Constants.API_URL + "CreateArticle" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        userId: loggedInUser.id,
        email: loggedInUser.email,
      },
      data: formData,
    })
      .then((response) => {
        alert("Article Created");
        ScrollToTop();
        navigate("/articles");
      })
      .catch((error) => {
        alert("Error Creating Article");
      });
  }

  function UpdateArticle() {
    var formData = new FormData();
    formData.append("id", article.id);
    if (title !== article.title) {
      formData.append("title", title);
    }
    if (slug !== article.slug) {
      formData.append("slug", slug);
    }
    if (body !== article.body) {
      formData.append("body", body);
    }
    if (plainTextBody !== article.plainTextBody) {
      formData.append("plain_text_body", plainTextBody);
    }
    if (publishDate != article.publishDate && publishDate != null) {
      formData.append("published_date", publishDate);
    }
    if (author !== article.author) {
      formData.append("author", author);
    }
    if (hidden !== article.hidden) {
      formData.append("hidden", hidden);
    }
    if (image !== article.image) {
      formData.append("image", image);
    }
    if (imageCaption !== article.imageCaption) {
      formData.append("image_caption", imageCaption);
    }
    if (featured !== article.featured) {
      formData.append("featured", featured);
    }
    if (featuredDate !== null && featuredDate !== article.featuredDate) {
      formData.append("featured_date", featuredDate);
    }
    let currentCategoryIds = article.categories.map((c) => c.id);
    if (categories.sort().join(",") !== currentCategoryIds.sort().join(",")) {
      formData.append("categories", categories.join(","));
    }
    if (summary !== article.summary) {
      formData.append("summary", summary);
    }
    axios({
      method: "put",
      url: Constants.API_URL + "UpdateArticle" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        userId: loggedInUser.id,
        email: loggedInUser.email,
      },
      data: formData,
    })
      .then((response) => {
        alert("Article Updated");
        ScrollToTop();
        navigate("/articles");
      })
      .catch((error) => {
        alert("Error Updating Article");
        console.log(error);
      });
  }

  function RemoveArticle() {
    if (!window.confirm("Are you sure you want to delete this article?")) {
      return;
    }
    let formData = new FormData();
    formData.append("id", article.id);
    axios({
      method: "delete",
      url: Constants.API_URL + "DeleteArticle" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        userId: loggedInUser.id,
        email: loggedInUser.email,
      },
      data: formData,
    })
      .then((response) => {
        alert("Article Deleted");
        ScrollToTop();
        navigate("/articles");
      })
      .catch((error) => {
        alert("Error Deleting Article");
      });
  }

  async function GetArticle() {
    let articleList = await GetArticles([{ key: "id", value: id }]);
    if (articleList.length === 0) {
      alert("Article not found");
      navigate("/articles");
      return;
    }
    SetArticle(articleList[0]);
  }

  function AddImageToBlob() {
    let containerClient = blobServiceClient.getContainerClient("images");
    console.log("imageToUpload");
    console.log(imageToUpload.size);
    let blockBlobClient = containerClient.getBlockBlobClient(
      "media/uploads/" + imageToUpload.name
    );
    blockBlobClient
      .uploadData(imageToUpload)
      .then((response) => {
        SetImage(
          "https://funkerimages.blob.core.windows.net/images/media/uploads/" +
            imageToUpload.name
        );
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (id != null) {
      GetArticle();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  console.log(
    '<script type="text/javascript" src="//www.avantlink.com/product_ad_widget/paw.php?pawid=170253&amp;pw=190078&amp;output=js"></script>'.replace(
      /[<>&'"]/g,
      function (c) {
        switch (c) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;";
          case "&":
            return "&amp;";
          case "'":
            return "&apos;";
          case '"':
            return "&quot;";
        }
      }
    )
  );

  useEffect(() => {
    if (article != null) {
      SetAuthor(article.author);
      SetTitle(article.title);
      SetBody(article.body);
      SetImage(article.image);
      SetSlug(article.slug);
      SetSummary(article.summary);
      SetAutoSummary(article.autoSummary);
      SetHidden(article.hidden);
      SetFeatured(article.featured);
      SetImageCaption(article.imageCaption);
      SetPublishDate(article.publishDate.substring(0, 10));
      SetFeaturedDate(
        article.featuredDate != null
          ? article.featuredDate.substring(0, 10)
          : null
      );
      SetCategories(article.categories.map((c) => c.id));
      SetPlainTextBody(
        article.plainTextBody != null ? article.plainTextBody : ""
      );
    }
  }, [article]);

  useEffect(() => {
    GeneratePreviewArticle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    title,
    body,
    plainTextBody,
    image,
    slug,
    hidden,
    imageCaption,
    summary,
    publishDate,
    featured,
    featuredDate,
    categories,
    author,
  ]);

  return (
    <div className="mainContent article addEdit">
      {!showPreview ? (
        <div className="mainContentBox addEdit">
          <div className="mainContentHeader addEditRow">
            <div className="mainContentInputGroup">
              <input
                className="mainContentInput"
                type="text"
                placeholder="Title"
                value={title}
                onChange={ChangeTitle}
              />
              <input
                className="mainContentInput"
                placeholder={author === "" ? "Author" : author}
                type="text"
                value={author}
                onChange={ChangeAuthor}
              />
            </div>
            <div className="mainContentInputGroup">
              <input
                className="mainContentInput"
                type="text"
                value={image}
                placeholder="Image Url"
                onChange={ChangeImage}
              />
              <input
                id="imageUpload"
                className="hidden"
                type="file"
                onChange={ChangeImageToUpload}
              />
              <div className="mainContentInputGroup button">
                <button
                  className="mainContentButton"
                  onClick={() => {
                    document.getElementById("imageUpload").click();
                  }}
                >
                  {imageToUpload != null ? imageToUpload.name : "Pick Image"}
                </button>
                <button className="mainContentButton" onClick={AddImageToBlob}>
                  Upload
                </button>
              </div>
            </div>
          </div>
          <div className="mainContentBody">
            <div className="mainContentText addEdit">
              <Editor
                apiKey={Constants.Tiny_Key}
                value={body}
                onInit={(evt, editor) =>
                  SetPlainTextBody(editor.getContent({ format: "text" }))
                }
                init={{
                  plugins: "link image code preview lists",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | code preview | numlist bullist",
                  height: "100%",
                  content_style: "body { background-color: #f2f2f2; } ",
                  allow_script_urls: true,
                  extended_valid_elements:
                    "script[src|async|defer|type|charset],iframe[id|src|srcdoc|width|height|name|align|frameborder|marginwidth|marginheight|scrolling]",
                }}
                onEditorChange={(content, editor) => {
                  SetPlainTextBody(editor.getContent({ format: "text" }));
                  SetBody(content);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Article previewMode={true} previewArticle={previewArticle} />
      )}
      <AddEditSidebar
        publishDate={publishDate}
        ChangePublishDate={ChangePublishDate}
        Submit={Submit}
        hidden={hidden}
        ChangeHidden={ChangeHidden}
        slug={slug}
        ChangeSlug={ChangeSlug}
        imageCaption={imageCaption}
        ChangeImageCaption={ChangeImageCaption}
        ShowPreview={ShowPreview}
        featured={featured}
        ChangeFeatured={ChangeFeatured}
        featuredDate={featuredDate}
        ChangeFeaturedDate={ChangeFeaturedDate}
        RemoveArticle={RemoveArticle}
        categories={categories}
        ChangeCategories={ChangeCategories}
        summary={summary}
        autoSummary={autoSummary}
        ChangeSummary={ChangeSummary}
      />
    </div>
  );
}

export default AddEditArticle;

import React, { useEffect, useState } from "react";
import * as Constants from "../Constants";
import axios from "axios";
import Comment from "./Comment/Comment";
import "react-tooltip/dist/react-tooltip.css";
import "../Styles/Funker530Comments.scss";
import NewCommentForm from "./Form/NewCommentForm";
import { Tooltip } from "react-tooltip";

function Funker530Comments(props) {
  const [comments, SetComments] = useState([]);
  const [userComments, SetUserComments] = useState([]);
  const [countComment, SetCountComment] = useState(null);
  const [basicInfo, SetBasicInfo] = useState({});
  const [commentsLoaded, SetCommentsLoaded] = useState(false);
  const [mainCommentsError, SetMainCommentsError] = useState("");

  function GenerateBasicInfo() {
    let basicInfoTemp = {
      articleId: props.articleId,
      canSeeAdminButtons: false,
    };
    if (props.userId != null) {
      basicInfoTemp = {
        ...basicInfoTemp,
        userId: +props.userId,
        username: props.username,
        userEmail: props.userEmail,
        userIsSuperAdmin: props.superAdmin,
        userIsModerator: props.groups.includes(1),
        userIsWriter: props.groups.includes(2),
        userIsEditor: props.groups.includes(3),
      };
      if (
        basicInfoTemp.userIsSuperAdmin ||
        basicInfoTemp.userIsWriter ||
        basicInfoTemp.userIsModerator
      ) {
        basicInfoTemp.canSeeAdminButtons = true;
      }
    }
    SetBasicInfo(basicInfoTemp);
  }

  function ProcessUserComments(comments) {
    let processedUserComments = comments.slice();
    let commentIdsToRemove = [];
    for (let i = 0; i < processedUserComments.length; i++) {
      if (
        processedUserComments[i].userId != null &&
        processedUserComments[i].userId.toString() ===
          basicInfo.userId.toString()
      ) {
        continue;
      }
      if (
        processedUserComments[i].children != null &&
        processedUserComments[i].children.length > 0
      ) {
        processedUserComments[i] = ProcessUserComments(
          processedUserComments[i].children
        );
        if (
          processedUserComments[i].children != null &&
          processedUserComments[i].children.length > 0
        ) {
          continue;
        }
      }
      commentIdsToRemove.push(processedUserComments[i].id);
    }
    if (commentIdsToRemove.length > 0) {
      processedUserComments = processedUserComments.filter(
        (comment) => !commentIdsToRemove.includes(comment.id)
      );
    }
    return processedUserComments;
  }

  function GetComments(refreshCache = false, attempt = 0) {
    SetCommentsLoaded(false);
    let params = new URLSearchParams();
    params.append("articleId", basicInfo.articleId);
    params.append("pullCache", refreshCache);
    if (basicInfo.articleId != null && basicInfo.userId != null) {
      params.append("userId", basicInfo.userId);
      params.append("username", basicInfo.username);
      params.append("userEmail", basicInfo.userEmail);
    }
    let apiEndpoint = "ReadComments";

    axios
      .get(Constants.API_URL + apiEndpoint, {
        params: params,
      })
      .then((response) => {
        let commentsMatch = false;
        let returnedComments = response.data;
        if (returnedComments.length > 1) {
          if (returnedComments[1].postId != null) {
            commentsMatch =
              basicInfo.articleId.toString() ===
              returnedComments[1].postId.toString();
          }
        }
        if (commentsMatch) {
          SetCountComment(returnedComments[0]);
          SetComments(returnedComments.slice(1));
          SetCommentsLoaded(true);
          return;
        }
        if (attempt < 3) {
          console.log("Comments 0 or didn't match. Trying again.");
          GetComments(false, attempt + 1);
          return;
        }
        if (returnedComments.length > 1) {
          SetMainCommentsError(
            "Error Loading Comments. Please Refresh to Try Again."
          );
          SetCountComment(null);
        } else {
          SetMainCommentsError("Be the first to comment!");
          SetCountComment(returnedComments[0]);
        }
        SetComments([]);
        SetCommentsLoaded(true);
      });
  }

  function LoginCheck(action) {
    if (
      basicInfo.userId == null ||
      basicInfo.userId === 0 ||
      basicInfo.userId === ""
    ) {
      if (action == null) {
        return false;
      }
      if (
        window.confirm(
          "You must be logged in to " + action + ". \n Click OK to sign up."
        )
      ) {
        let redirectHost = window.location.host;
        if (redirectHost.includes("blog.")) {
          redirectHost = redirectHost.replace("blog.", "");
        }
        window.location.href =
          "https://" +
          redirectHost +
          "/accounts/signup?next=" +
          window.location.href;
      }
      return false;
    }
    return true;
  }

  function GenerateCommentCountText() {
    if (countComment != null) {
      if (!commentsLoaded) {
        return "Refreshing Comments...";
      }
      if (comments.length > 1) {
        let commentsCount = +countComment.content;
        let itemText = " Comments";
        if (commentsCount === 1) {
          itemText = " Comment";
        }
        return countComment.content + itemText;
      }
      if (mainCommentsError !== "") {
        return mainCommentsError;
      }
      return "Be the first to comment!";
    }
    return "Loading Comments...";
  }

  function FullRefresh(refreshCache = false) {
    GetComments(refreshCache, 0);
  }

  useEffect(() => {
    if (
      comments.length > 0 &&
      basicInfo.userId != null &&
      basicInfo.userId !== 0 &&
      basicInfo.userId !== ""
    ) {
      SetUserComments(ProcessUserComments(comments));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  useEffect(() => {
    if (countComment != null) {
      let commentCountDisplay = document.getElementById("commentCountDisplay");
      if (commentCountDisplay != null) {
        commentCountDisplay.textContent = " " + countComment.content;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countComment]);

  useEffect(() => {
    if (basicInfo.articleId != null) {
      FullRefresh();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo]);

  useEffect(() => {
    GenerateBasicInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div
      id="CommentSectionContainer"
      data-funkertheme={props.darkMode ? "dark" : "light"}
    >
      <Tooltip id="commentTooltip" />
      <NewCommentForm
        basicInfo={basicInfo}
        FullRefresh={FullRefresh}
        LoginCheck={LoginCheck}
      />
      {userComments.length > 0 ? (
        <>
          <div className="commentsCount">Your Parent Comments</div>
          <div className="commentsList">
            {userComments.map((comment, index) => (
              <Comment
                key={index}
                comment={comment}
                basicInfo={basicInfo}
                FullRefresh={FullRefresh}
                keySuffix="u"
                LoginCheck={LoginCheck}
                parentLevel={-50}
              />
            ))}
          </div>
          -
        </>
      ) : null}
      <div className="commentsCount">{GenerateCommentCountText()}</div>
      <div className="commentsList">
        {comments.map((comment, index) => (
          <Comment
            key={index}
            comment={comment}
            basicInfo={basicInfo}
            FullRefresh={FullRefresh}
            keySuffix=""
            LoginCheck={LoginCheck}
            parentLevel={0}
          />
        ))}
      </div>
    </div>
  );
}

export default Funker530Comments;

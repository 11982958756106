import React, { Fragment, useEffect, useState } from "react";
import * as Constants from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGem } from "@fortawesome/pro-light-svg-icons";
import {
  faThumbTack,
  faMicrophone,
  faEdit,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faPencil } from "@fortawesome/sharp-solid-svg-icons";
import { faRectanglePro } from "@fortawesome/pro-solid-svg-icons";
import AdminBan from "./CommentAdminActions/AdminBan";
import AdminDelete from "./CommentAdminActions/AdminDelete";
import AdminHide from "./CommentAdminActions/AdminHide";
import AdminShadow from "./CommentAdminActions/AdminShadow";
import CommentReply from "./CommentUserActions/CommentReply";
import UpDownVote from "./CommentUserActions/UpDownVote";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Comment(props) {
  const [awards, SetAwards] = useState([]);
  const [minimizeChildren, SetMinimizeChildren] = useState(
    props.parentLevel > 2
  );
  const [dimParent, SetDimParent] = useState(false);
  const [editMode, SetEditMode] = useState(false);
  const [editText, SetEditText] = useState(props.comment.content);

  function ToggleMinimizeChildren() {
    SetMinimizeChildren(!minimizeChildren);
  }

  function EditMode() {
    SetEditMode(!editMode);
  }

  function ChangeEditText(event) {
    SetEditText(event.target.value);
  }

  function SubmitEdit() {
    axios({
      method: "get",
      url: Constants.COMMENTS_API_URL + "UpdateComment",
      params: {
        articleId: props.basicInfo.articleId,
        userId: props.basicInfo.userId,
        username: props.basicInfo.username,
        userEmail: props.basicInfo.userEmail,
        field: "content",
        value: editText,
        commentGuid: props.comment.id,
      },
    })
      .then((response) => {
        alert("Comment edited succesfully! It will appear shortly");
        EditMode();
        props.FullRefresh();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetAvatarUrl() {
    if (props.comment.userPicURL != null) {
      let includesUrl = props.comment.userPicURL.includes("http");
      if (includesUrl) {
        return props.comment.userPicURL;
      }
      return Constants.IMAGE_URL + props.comment.userPicURL;
    }
    return Constants.IMAGE_URL + Constants.DEFAULT_AVATAR_IMAGE;
  }

  function GenerateAwardObjects() {
    let awards = [
      {
        name: "Pinned",
        type: "pinned",
        icon: faThumbTack,
        awarded: props.comment.pinned,
        toolTip: "Pinned Comment",
      },
      {
        name: "Top Comment",
        type: "topComm",
        icon: faGem,
        awarded: props.comment.topComment,
        toolTip: "Top Comment",
      },
      {
        name: "Joker",
        type: "joker",
        icon: faMicrophone,
        awarded: props.comment.joker,
        toolTip: "Joker",
      },
      {
        name: "Master Debater",
        type: "md",
        icon: faPencil,
        awarded: props.comment.masterDebater,
        toolTip: "Master Debater",
      },
    ];
    SetAwards(awards);
  }

  function AwardComment(type, value) {
    axios({
      method: "get",
      url: Constants.COMMENTS_API_URL + "AwardComment",
      params: {
        articleId: props.basicInfo.articleId,
        commentGuid: props.comment.id,
        userId: props.basicInfo.userId,
        userEmail: props.basicInfo.userEmail,
        field: type,
        value: value,
      },
    })
      .then((response) => {
        alert(value ? "Award Given!" : "Award Removed!");
        props.FullRefresh();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GenerateTooltipPrefix(awarded) {
    if (props.canSeeAdminButtons) {
      let tooltipPrefix = "Add ";
      if (awarded) {
        tooltipPrefix = "Remove ";
      }
      return tooltipPrefix;
    }
    return "";
  }

  function GenerateAwardClassName(award) {
    let className = "commentAward" + award.name.replace(" ", "");

    if (award.awarded) {
      return className;
    }
    if (props.basicInfo.canSeeAdminButtons) {
      className += " commentAwardFaded";
    } else {
      className += " commentAwardHidden";
    }
    return className;
  }

  useEffect(() => {
    if (props.comment != null) {
      GenerateAwardObjects();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.comment]);

  return (
    <div
      className={
        dimParent ? "commentContainer parentDimmed" : "commentContainer"
      }
    >
      <div
        className={
          props.comment.sb
            ? "commentContentContainer shadowBanned"
            : props.comment.ml === -1
            ? "commentContentContainer banned"
            : props.comment.ml === -3
            ? "commentContentContainer manuallyModerated"
            : "commentContentContainer"
        }
      >
        <div
          className={
            props.comment.userSubscribed
              ? "commentAvatar commentProUser"
              : "commentAvatar"
          }
        >
          <LazyLoadImage src={GetAvatarUrl()} alt="Avatar" />
        </div>
        <div className="commentBody">
          <div className="commentHeader">
            <div className="commentAuthor">{props.comment.username}</div>
            {props.comment.userSubscribed ? (
              <FontAwesomeIcon
                id={props.comment.id + "proIcon"}
                onClick={() =>
                  (window.location.href =
                    Constants.MAIN_SITE_URL + "/funker530_premium")
                }
                className="commentProIcon"
                data-tooltip-id="commentTooltip"
                data-tooltip-content="Premium User"
                icon={faRectanglePro}
              />
            ) : null}
            <div className="commentMetadata">
              <div className="commentDate">{props.comment.prettyDate}</div>
              {awards.map((award, index) => (
                <Fragment key={index}>
                  <FontAwesomeIcon
                    id={props.comment.id + award.name}
                    className={GenerateAwardClassName(award)}
                    icon={award.icon}
                    data-tooltip-id="commentTooltip"
                    data-tooltip-content={
                      GenerateTooltipPrefix(award.awarded) + award.toolTip
                    }
                    onClick={() => {
                      if (props.basicInfo.canSeeAdminButtons) {
                        AwardComment(award.type, !award.awarded);
                      }
                    }}
                  />
                </Fragment>
              ))}
            </div>
          </div>
          {!editMode ? (
            <div className="commentText">
              {props.comment.content != null &&
              props.comment.content.trim() !== ""
                ? props.comment.content
                : "[deleted by " + props.comment.username + "]"}
            </div>
          ) : (
            <div className="commentEditForm">
              <textarea
                className="commentEditFormInput"
                type="textarea"
                value={editText}
                onChange={ChangeEditText}
              />
              <div class="commentEditPostButton">
                <FontAwesomeIcon
                  className="commentEditPostButtonIcon"
                  icon={faPenToSquare}
                />
                <div className="commentEditPostButtonText" onClick={SubmitEdit}>
                  Edit Comment
                </div>
              </div>
            </div>
          )}
          <div className="commentActions">
            <UpDownVote
              comment={props.comment}
              basicInfo={props.basicInfo}
              FullRefresh={props.FullRefresh}
              LoginCheck={props.LoginCheck}
            />
            <CommentReply
              comment={props.comment}
              basicInfo={props.basicInfo}
              FullRefresh={props.FullRefresh}
              LoginCheck={props.LoginCheck}
            />
            {props.basicInfo.userId != null &&
            props.basicInfo.userId.toString() ===
              props.comment.userId.toString() ? (
              <>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="commentEdit"
                  id={props.comment.id + "commentEdit"}
                  data-tooltip-id="commentTooltip"
                  data-tooltip-content="Edit"
                  onClick={EditMode}
                />
              </>
            ) : (
              <div className="commentEdit"></div>
            )}
            {props.basicInfo.canSeeAdminButtons ? (
              <div className="commentAdminActions">
                <AdminDelete
                  id={props.comment.id}
                  anchorId={props.comment.id + "AdminDelete"}
                  username={props.comment.username}
                  userId={props.comment.userId}
                  adminUserId={props.basicInfo.userId}
                  adminUserEmail={props.basicInfo.userEmail}
                  articleId={props.basicInfo.articleId}
                  FullRefresh={props.FullRefresh}
                />
                <AdminShadow
                  id={props.comment.id}
                  anchorId={props.comment.id + "AdminShadow"}
                  username={props.comment.username}
                  userId={props.comment.userId}
                  adminUserId={props.basicInfo.userId}
                  adminUserEmail={props.basicInfo.userEmail}
                  articleId={props.basicInfo.articleId}
                  FullRefresh={props.FullRefresh}
                />
                <AdminBan
                  id={props.comment.id}
                  anchorId={props.comment.id + "AdminBan"}
                  username={props.comment.username}
                  userId={props.comment.userId}
                  adminUserId={props.basicInfo.userId}
                  adminUserEmail={props.basicInfo.userEmail}
                  articleId={props.basicInfo.articleId}
                  FullRefresh={props.FullRefresh}
                />
                <AdminHide
                  id={props.comment.id}
                  anchorId={props.comment.id + "AdminHide"}
                  username={props.comment.username}
                  userId={props.comment.userId}
                  adminUserId={props.basicInfo.userId}
                  adminUserEmail={props.basicInfo.userEmail}
                  articleId={props.basicInfo.articleId}
                  ml={props.comment.ml}
                  FullRefresh={props.FullRefresh}
                />
              </div>
            ) : (
              <div className="commentAdminActions"></div>
            )}
          </div>
        </div>
      </div>
      {props.comment.children.length > 0 ? (
        <div
          className={
            minimizeChildren ? "commentChildren minimized" : "commentChildren"
          }
        >
          <div
            className="commentMinimizeChildren"
            onMouseEnter={() => SetDimParent(true)}
            onMouseLeave={() => SetDimParent(false)}
            onClick={ToggleMinimizeChildren}
          >
            {minimizeChildren ? (
              <div className="commentMinimizeChildrenExpand">
                +
                <div className="commentMinimizeChildrenExpandText">
                  Show Replies
                </div>
              </div>
            ) : (
              "-"
            )}
          </div>
          <div onClick={minimizeChildren ? ToggleMinimizeChildren : null}>
            {minimizeChildren
              ? null
              : props.comment.children.map((child, index) => (
                  <Comment
                    key={index}
                    comment={child}
                    basicInfo={props.basicInfo}
                    FullRefresh={props.FullRefresh}
                    LoginCheck={props.LoginCheck}
                    parentLevel={props.parentLevel + 1}
                  />
                ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Comment;

export const API_URL = "https://funker530blog-fnc.azurewebsites.net/api/";
// export const API_URL = "http://localhost:7071/api/";
export const API_KEY_ONLY =
  "aoHKQ3vQDx-eHiozDIYH58INzoF9ELvgvdB6Z65hd7HzAzFuh0XrmQ==";
export const API_KEY = "?code=" + API_KEY_ONLY;
export const API_URL_AD = "https://green.funker530.com/api/";
export const API_KEY_AD_ONLY =
  "sMYsoM1YowbUGryzG47fyGhv7JQ8GFm85SottVJJg3/AhtnBec8nzg==";
export const API_KEY_AD = "?code=" + API_KEY_AD_ONLY;
export const BLOB_STORAGE_URL =
  "https://funkerimages.blob.core.windows.net/?sv=2021-06-08&ss=b&srt=sco&sp=rwactf&se=2099-01-18T01:29:28Z&st=2023-01-17T17:29:28Z&spr=https&sig=QC0w2ryYn9EWg2TZH0nonpGpekbYvWtyob14i75pGik%3D";
// export const Main_Site_URL = "https://funker530.com/";
export const Main_Site_URL = "http://127.0.0.1:8000/";
export const Tiny_Key = "758p3p6omrvksdfnommrnolv2u1z2uqc5z8e47n92z6q3wr9";

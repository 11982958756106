import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "../../Constants";
import { useNavigate } from "react-router-dom";

function HeaderDropdown(props) {
  const navigate = useNavigate();

  function HandleSelfNavigation(page) {
    navigate(page);
  }

  function HandleMainNavigation(page) {
    window.open(Constants.Main_Site_URL + page, "_self");
  }

  return (
    <div className={"dropdown " + props.typeClass}>
      <div className={"dropdownContainer " + props.typeClass}>
        {props.dropdownItems.map((item, index) => (
          <div
            className={"dropdownItem " + props.typeClass}
            onClick={() =>
              item.type !== "mainSite"
                ? HandleSelfNavigation(item.navigationPath)
                : HandleMainNavigation(item.navigationPath)
            }
            key={index}
          >
            <FontAwesomeIcon
              className={"dropdownItemIcon " + props.typeClass}
              icon={item.icon}
            />
            <div className={"dropdownItemText " + props.typeClass}>
              {item.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HeaderDropdown;

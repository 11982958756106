import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../Context";

function AddArticleButton(props) {
  const navigate = useNavigate();
  const { ScrollToTop } = useSiteContext();

  return (
    <div className="addArticleButtonContainer">
      <div
        className="addArticleButton"
        onClick={() => {
          ScrollToTop();
          navigate("/admin");
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  );
}

export default AddArticleButton;

import React from "react";
import * as Constants from "../../../Constants";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

function UpDownVote(props) {
  const hasUpvoted = props.comment.usersUpvoted.includes(
    props.basicInfo.userId
  );
  const hasDownvoted = props.comment.usersDownvoted.includes(
    props.basicInfo.userId
  );

  function UpVote() {
    if (hasUpvoted) {
      CommentVote("upvoteRemove");
    } else {
      CommentVote("upvote");
    }
  }

  function DownVote() {
    if (hasDownvoted) {
      CommentVote("downvoteRemove");
    } else {
      CommentVote("downvote");
    }
  }

  function CommentVote(voteType) {
    if (!props.LoginCheck("vote")) {
      return;
    }
    if (props.basicInfo.userId == null || props.basicInfo.userId === "") {
      alert("You must be logged in to vote on a comment");
      return;
    }
    axios({
      method: "post",
      url: Constants.COMMENTS_API_URL + "VoteComment",
      params: {
        articleId: props.basicInfo.articleId,
        commentGuid: props.comment.id,
        userId: props.basicInfo.userId,
        userEmail: props.basicInfo.userEmail,
        vote: voteType,
      },
    })
      .then((response) => {
        props.FullRefresh();
      })
      .catch((error) => {
        alert("Error voting on comment");
        console.log(error);
      });
  }

  return (
    <>
      <div className="commentVoting">
        <FontAwesomeIcon
          className={hasUpvoted ? "commentUpvoteActive" : "commentUpvote"}
          icon={faArrowUp}
          onClick={UpVote}
        />
        {props.comment.voteDifference}
        <FontAwesomeIcon
          className={hasDownvoted ? "commentDownvoteActive" : "commentDownvote"}
          icon={faArrowDown}
          onClick={DownVote}
        />
      </div>
    </>
  );
}

export default UpDownVote;

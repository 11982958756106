import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faMagnifyingGlass,
  faHome,
  faChartLineUp,
  faClock,
  faThumbsUp,
  faPlus,
  faRss,
  faShoppingCart,
  faBinoculars,
  faShieldHalved,
  faCog,
  faMoon,
  faPeopleGroup,
  faRectanglePro,
  faArrowCircleLeft,
  faCaretRight,
  faNewspaper,
} from "@fortawesome/pro-solid-svg-icons";
import * as Constants from "../../../Constants";
import { useSiteContext } from "../../../Context";
import HeaderDropdown from "../HeaderDropdown";
import { useNavigate } from "react-router-dom";

function HeaderSidebar(props) {
  const [showCategories, SetShowCategories] = useState(false);
  const {
    IsPremium,
    articleCategories,
    MakeDropdownItem,
    darkMode,
    DarkModeToggle,
    loggedInUser,
    ScrollToTop,
  } = useSiteContext();
  const navigate = useNavigate();

  function ShowCategories() {
    SetShowCategories(!showCategories);
  }

  function ToggleDarkMode() {
    DarkModeToggle(!darkMode);
  }

  function HandleNavigation(path) {
    ScrollToTop();
    if (path === "/") {
      navigate("/");
    } else {
      navigate("/articles" + path);
    }
    props.CloseSidebar();
  }

  function DropdownInfo() {
    let dropdownItems = [];
    articleCategories.forEach((category) => {
      dropdownItems.push(
        MakeDropdownItem(
          faCaretRight,
          category.name.toUpperCase(),
          `/articles?category=${category.id}`,
          "blogSite"
        )
      );
    });
    return dropdownItems;
  }

  function GetPath() {
    let path = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/"),
      window.location.pathname.length
    );
    if (path === "/articles") {
      path = "/";
    }
    return path;
  }

  return (
    <div
      className={
        props.sidebarSlidingOut
          ? "headerSidebarContainer slidingOut"
          : "headerSidebarContainer"
      }
    >
      <div className="headerSidebarHeader">
        <div className="headerSidebarHeaderTop">
          <div className="headerSidebarItem header">
            <div className="headerSidebarText header">POWERED BY</div>
          </div>
          <div className="headerSidebarItem icon" onClick={props.CloseSidebar}>
            <FontAwesomeIcon className="headerSidebarIcon" icon={faXmark} />
          </div>
        </div>
        <div className="headerSidebarHeaderBottom">
          <img
            className="headerSidebarLogo"
            src="https://images.funker530.com/images/media/images/sponsor_1_ar500.original.png"
            onClick={() =>
              window.open(
                "https://www.ar500armor.com/?utm_source=Funker530Sidebar"
              )
            }
            alt=""
          />
          <img
            className="headerSidebarLogo"
            src="https://images.funker530.com/images/media/images/sponsor_2_atn.original.png"
            onClick={() =>
              window.open(
                "https://www.atncorp.com/?utm_source=Funker530Sidebar"
              )
            }
            alt=""
          />
        </div>
      </div>
      <div className="headerSidebarContent">
        <div
          className="headerSidebarItem links"
          onClick={() => HandleNavigation("/")}
        >
          <FontAwesomeIcon className="headerSidebarIcon links" icon={faHome} />
          <div className="headerSidebarText">HOME</div>
        </div>
        <div
          className="headerSidebarItem links"
          onClick={() =>
            HandleNavigation("?earliestToShow=7&sorting=views-desc")
          }
        >
          <FontAwesomeIcon
            className="headerSidebarIcon links"
            icon={faChartLineUp}
          />
          <div className="headerSidebarText">TRENDING</div>
        </div>
        <div
          className="headerSidebarItem links"
          onClick={() => HandleNavigation("")}
        >
          <FontAwesomeIcon className="headerSidebarIcon links" icon={faClock} />
          <div className="headerSidebarText">LATEST</div>
        </div>
        <div className="headerSidebarItem links">
          <FontAwesomeIcon className="headerSidebarIcon links" icon={faPlus} />
          <div className="headerSidebarText" onClick={ShowCategories}>
            CATEGORIES
          </div>
        </div>
        {showCategories ? (
          <HeaderDropdown
            typeClass={"sidebar"}
            dropdownItems={DropdownInfo()}
          />
        ) : null}
        <div
          className="headerSidebarItem links"
          onClick={() =>
            window.open(
              "https://www.bunkerbranding.com/pages/funker530",
              "_window"
            )
          }
        >
          <FontAwesomeIcon
            className="headerSidebarIcon links"
            icon={faShoppingCart}
          />
          <div className="headerSidebarText">MERCH</div>
        </div>
        <div
          className="headerSidebarItem links"
          onClick={() =>
            window.open(
              "https://www.atncorp.com/?utm_source=Funker530",
              "_window"
            )
          }
        >
          <FontAwesomeIcon
            className="headerSidebarIcon links"
            icon={faBinoculars}
          />
          <div className="headerSidebarText">SHOP NIGHT VISION</div>
        </div>
        <div
          className="headerSidebarItem links"
          onClick={() =>
            window.open(
              "https://www.ar500armor.com/?utm_source=Funker530Sidebar",
              "_window"
            )
          }
        >
          <FontAwesomeIcon
            className="headerSidebarIcon links"
            icon={faShieldHalved}
          />
          <div className="headerSidebarText">SHOP ARMOR</div>
        </div>
        <div
          className="headerSidebarItem links"
          onClick={() =>
            window.open(Constants.Main_Site_URL + "/accounts/update", "_self")
          }
        >
          <FontAwesomeIcon className="headerSidebarIcon links" icon={faCog} />
          <div className="headerSidebarText">EDIT PROFILE</div>
        </div>
        <div className="headerSidebarItem links" onClick={ToggleDarkMode}>
          <FontAwesomeIcon className="headerSidebarIcon links" icon={faMoon} />
          <div className="headerSidebarText links">TOGGLE DARK</div>
        </div>
        <div
          className="headerSidebarItem links"
          onClick={() =>
            window.open(Constants.Main_Site_URL + "/our-team", "_self")
          }
        >
          <FontAwesomeIcon
            className="headerSidebarIcon links"
            icon={faPeopleGroup}
          />
          <div className="headerSidebarText">OUR TEAM</div>
        </div>
        {IsPremium() ? null : (
          <div
            className="headerSidebarItem links"
            onClick={() =>
              window.open(
                Constants.Main_Site_URL +
                  "/funker530_premium?next=" +
                  window.location.href,
                "_self"
              )
            }
          >
            <FontAwesomeIcon
              className="headerSidebarIcon links pro"
              icon={faRectanglePro}
            />
            <div className="headerSidebarText">FUNKER530 PREMIUM</div>
          </div>
        )}
        <div
          className="headerSidebarItem links"
          onClick={() =>
            loggedInUser != null
              ? window.open(
                  Constants.Main_Site_URL +
                    "accounts/logout?next=" +
                    window.location.href,
                  "_self"
                )
              : window.open(
                  Constants.Main_Site_URL +
                    "accounts/blog-verify-logged-in" +
                    GetPath(),
                  "_self"
                )
          }
        >
          <FontAwesomeIcon
            className="headerSidebarIcon links"
            icon={faArrowCircleLeft}
          />
          <div className="headerSidebarText">
            {loggedInUser != null ? "LOGOUT " : "LOG IN"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderSidebar;

import React from "react";
import { useSiteContext } from "../../../../Context";

function AddEditSidebar(props) {
  const { articleCategories } = useSiteContext();

  return (
    <div className="contentSidebarContainer">
      <div className="contentSidebar addEdit">
        <div className="addEditSidebarItem">
          <div className="addEditSidebarItemText">Publish Date</div>
          <input
            className="addEditSidebarItemInput textValueBoxes"
            type="date"
            value={props.publishDate == null ? "" : props.publishDate}
            onChange={props.ChangePublishDate}
          />
        </div>
        <div className="addEditSidebarItem">
          <div className="addEditSidebarItemText">Slug</div>
          <input
            className="addEditSidebarItemInput textValueBoxes"
            type="text"
            value={props.slug === "" ? "" : props.slug}
            onChange={props.ChangeSlug}
          />
        </div>
        <div className="addEditSidebarItem">
          <div className="addEditSidebarItemText">Image Caption</div>
          <input
            className="addEditSidebarItemInput textValueBoxes"
            type="text"
            value={props.imageCaption === "" ? "" : props.imageCaption}
            onChange={props.ChangeImageCaption}
          />
        </div>
        <div className="addEditSidebarItem">
          <div className="addEditSidebarItemTextContainer">
            <div className="addEditSidebarItemText">Summary</div>
            <div className="addEditSidebarItemText subText">
              (Will auto generate if left blank)
            </div>
          </div>
          <textarea
            className="addEditSidebarItemInput textValueBoxes summary"
            type="text"
            value={props.summary != props.autoSummary ? props.summary : ""}
            onChange={props.ChangeSummary}
            placeholder={
              props.autoSummary != ""
                ? "(Auto-Generated)" + props.autoSummary
                : "Enter a summary for the article"
            }
          />
        </div>
        <div className="addEditSidebarItem">
          <div className="addEditSidebarItemText">Categories</div>
          <div className="addEditSidebarItem categoryGrid">
            {articleCategories.map((category, index) => (
              <div key={index} className="addEditSidebarItemInput categories">
                <input
                  name={category.name + "Checkbox"}
                  type="checkbox"
                  className="addEditCheckbox"
                  checked={props.categories.includes(category.id)}
                  onChange={() => {
                    props.ChangeCategories(category.id);
                  }}
                />
                <label htmlFor={category.name + "Checkbox"}>
                  {category.name}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="addEditSidebarItem doubleItem">
          <div className="addEditSidebarDoubleItem">
            <div className="addEditSidebarItemInput">
              <input
                name="featuredCheckbox"
                className="addEditCheckbox"
                type="checkbox"
                checked={props.featured}
                onChange={props.ChangeFeatured}
              />
              <label
                htmlFor="featuredCheckbox"
                className="addEditSidebarItemText"
              >
                Featured
              </label>
            </div>
          </div>
          {props.featured ? (
            <div className="addEditSidebarItem doubleItem">
              <div className="addEditSidebarItemText">Start Date:</div>
              <div className="addEditSidebarItemInput">
                <input
                  type="date"
                  value={props.featuredDate == null ? "" : props.featuredDate}
                  onChange={props.ChangeFeaturedDate}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="addEditSidebarItem doubleItem">
          <div className="addEditSidebarItemInput">
            <input
              name="hiddenCheckbox"
              className="addEditCheckbox"
              type="checkbox"
              checked={props.hidden}
              onChange={props.ChangeHidden}
            />
            <label htmlFor="hiddenCheckbox" className="addEditSidebarItemText">
              Hidden
            </label>
          </div>
        </div>
        <div className="addEditButtonsContainer">
          <div className="adminArticleButton" onClick={() => props.Submit()}>
            Submit
          </div>
          <div
            className="adminArticleButton"
            onClick={() => props.ShowPreview()}
          >
            Preview
          </div>
        </div>
        <div
          className="adminArticleButton deleteArticle"
          onClick={() => props.RemoveArticle()}
        >
          Delete
        </div>
      </div>
    </div>
  );
}

export default AddEditSidebar;

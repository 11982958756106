import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEye } from "@fortawesome/pro-regular-svg-icons";
import { useSiteContext } from "../../../../../Context";
import { useNavigate } from "react-router-dom";

function MostRecent() {
  const [mostRecent, SetMostRecent] = useState([]);
  const { GetArticles, MakeParameterItem, ScrollToTop } = useSiteContext();
  const [nextPageForRecent, SetNextPageForRecent] = useState(1);
  const navigate = useNavigate();

  function GetMostRecent() {
    let limit = 3;
    let params = [];
    params.push(MakeParameterItem("limit", limit.toString()));
    params.push(MakeParameterItem("page", nextPageForRecent.toString()));
    GetArticles(params).then((data) => {
      SetMostRecent(mostRecent.concat(data));
      SetNextPageForRecent(nextPageForRecent + 1);
    });
  }

  useEffect(() => {
    GetMostRecent();
  }, []);

  return (
    <div className="mainContentBox mostRecent">
      <div className="mainContentHeader mostRecent">
        <div className="mainContentHeaderTitle mostRecent">
          <FontAwesomeIcon
            icon={faClock}
            className="mainContentHeaderIcon mostRecent"
          />
          MOST RECENT ARTICLES
        </div>
      </div>
      <div className="mainContentBody mostRecent">
        <div className="mainContentBodyContent mostRecent">
          {mostRecent.length > 0
            ? mostRecent.map((item, index) => (
                <div
                  className="mostRecentItemContainer"
                  key={index}
                  onClick={() => {
                    ScrollToTop();
                    navigate("/article/" + item.slug);
                  }}
                >
                  <div className="mostRecentItemTitle">{item.title}</div>
                  <div className="mostRecentItem">
                    <div className="mostRecentItemViews">
                      <FontAwesomeIcon className="icon eye" icon={faEye} />
                      {item.views}
                    </div>
                    <div className="mostRecentItemDate">
                      {item.publishDate.substring(0, 10)}
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <div className="mainContentFooter mostRecent" onClick={GetMostRecent}>
        <div className="mainContentFooterText mostRecent">SEE MORE</div>
      </div>
    </div>
  );
}

export default MostRecent;

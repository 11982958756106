import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as Constants from "../../../Constants";

function AdminHide(props) {
  function AdminHide(value) {
    axios({
      method: "post",
      url: Constants.COMMENTS_API_URL + "ManuallyModerate",
      params: {
        articleId: props.articleId,
        commentGuid: props.id,
        value: value,
        userId: props.adminUserId,
        userEmail: props.adminUserEmail,
      },
    })
      .then((response) => {
        alert("This comment has been hidden");
        props.FullRefresh(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faEye}
        id={props.id + "AdminHide"}
        className="commentAdminDelete"
        data-tooltip-id="commentTooltip"
        data-tooltip-content={
          props.ml !== -3 ? "Hide Comment" : "Unhide Comment"
        }
        onClick={() =>
          props.ml === -3 ? AdminHide("False") : AdminHide("True")
        }
      />
    </>
  );
}

export default AdminHide;

import React, { useRef, useState, useEffect, forwardRef } from "react";
import ArticleTableOfContents from "./ArticleTableOfContents";
import InnerHTML from "dangerously-set-html-content";

function ArticleBody(props) {
  const bodyRef = useRef(null);
  const [processedBody, SetProcessedBody] = useState("");

  function ProcessBody() {
    if (props.article == null) {
      return;
    }
    let iframe = props.article.body.match(
      /<script[\s\S]*?>[\s\S]*?<\/script>/gi
    )[0];
    iframe = iframe.replace(/[<>&'"]/g, function (c) {
      switch (c) {
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case "&":
          return "&amp;";
        case "'":
          return "&apos;";
        case '"':
          return "&quot;";
      }
    });
    iframe = props.article.body.replace(
      /<script[\s\S]*?>[\s\S]*?<\/script>/gi,
      '<p><iframe class="scriptTagWrapper" src="about:blank" srcdoc="' +
        iframe +
        '" frameBorder=0></iframe></p><script>var iframes = document.querySelectorAll(".scriptTagWrapper");console.log(iframes);for(let i=0;i < iframes.length;i++){iframes[i].onload = function(){iframes[i].style.height = (iframes[i].contentWindow.document.body.scrollHeight + 40) + "px";}}</script>'
    );
    SetProcessedBody(iframe);
  }

  useEffect(() => {
    ProcessBody();
  }, [props.article]);

  return (
    <div className="mainContentBody article">
      <div className="mainContentImageContainer article">
        <img
          className="mainContentBodyItemImage article"
          src={props.article.image}
          alt={props.article.title}
        />
        <div className="mainContentBodyItemImageText article">
          {props.article.imageCaption}
        </div>
      </div>
      <ArticleTableOfContents
        title={props.article.title}
        body={processedBody}
      />
      {processedBody != "" ? (
        <InnerHTML
          id="articleBody"
          className="mainContentText article"
          html={processedBody}
        />
      ) : null}
    </div>
  );
}

export default ArticleBody;

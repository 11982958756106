import React from "react";
import MainContent from "./MainContent/MainContent";

function MainScreenContent(props) {
  return (
    <div className="mainScreenContentContainer">
      <MainContent article={props.article} />
    </div>
  );
}

export default MainScreenContent;

import React, { useEffect, useState } from "react";
import TableOfContentsItem from "./TableOfContentsItem";
import { useSiteContext } from "../../../../../Context";

function ArticleTableOfContents(props) {
  const [tableOfContents, SetTableOfContents] = useState([]);
  const { ScrollToTop } = useSiteContext();

  function GenerateTableOfContents() {
    let body = document.getElementById("articleBody");
    if (body == null) {
      return null;
    }
    let allHeaders = body.querySelectorAll("h1, h2, h3, h4, h5, h6");
    if (allHeaders.length == 0) {
      return [];
    }
    let newTableOfContents = [];
    let levelOrder = ["h1", "h2", "h3", "h4", "h5", "h6"];
    let previousHeader = null;
    for (let i = 0; i < allHeaders.length; i++) {
      let currentHeader = ProcessElement(allHeaders[i], i);
      let currentHeaderLevel = levelOrder.indexOf(
        currentHeader.element.tagName.toLowerCase()
      );
      if (previousHeader != null) {
        let previousHeaderLevel = levelOrder.indexOf(
          previousHeader.element.tagName.toLowerCase()
        );
        if (currentHeaderLevel > previousHeaderLevel) {
          currentHeader.id = previousHeader.id + "." + i;
          currentHeader.parent = previousHeader;
          previousHeader.children.push(currentHeader);
          previousHeader = currentHeader;
        } else if (currentHeaderLevel == previousHeaderLevel) {
          currentHeader.id = previousHeader.id + "." + i;
          currentHeader.parent = previousHeader.parent;
          if (previousHeader.parent != null) {
            previousHeader.parent.children.push(currentHeader);
          } else {
            newTableOfContents.push(currentHeader);
          }
        } else {
          let parent = previousHeader.parent;
          while (parent != null) {
            let parentLevel = levelOrder.indexOf(
              parent.element.tagName.toLowerCase()
            );
            if (currentHeaderLevel > parentLevel) {
              break;
            }
            parent = parent.parent;
          }
          if (parent != null) {
            currentHeader.id = parent.id + "." + i;
            currentHeader.parent = parent;
            parent.children.push(currentHeader);
            previousHeader = currentHeader;
          } else {
            currentHeader.id = newTableOfContents.length;
            newTableOfContents.push(currentHeader);
            previousHeader = currentHeader;
          }
        }
      } else {
        currentHeader.id = newTableOfContents.length;
        newTableOfContents.push(currentHeader);
        previousHeader = currentHeader;
      }
    }
    return newTableOfContents;
  }

  function ProcessElement(element, id) {
    let header = {
      element: element,
      id: id,
      text: "",
      parent: null,
      children: [],
    };
    if (element != null) {
      header.text = element.innerText;
    }
    return header;
  }

  useEffect(() => {
    setTimeout(() => {
      SetTableOfContents(GenerateTableOfContents());
    }, 100);
  }, [props.title, props.body]);

  return (
    <div className="articleTableOfContents">
      <div className="articleTableOfContentsTitle">Table of Contents</div>
      <div
        className="articleTableOfContentsArticleTitle"
        onClick={() => ScrollToTop()}
      >
        {props.title}
      </div>
      <div className="articleTableOfContentsItems">
        {tableOfContents != null
          ? tableOfContents.map((c) => (
              <TableOfContentsItem key={c.id} header={c} />
            ))
          : null}
      </div>
    </div>
  );
}

export default ArticleTableOfContents;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as Constants from "../../Constants";

function NewCommentForm(props) {
  const [newCommentPosting, SetNewCommentPosting] = useState(false);
  const [newCommentText, SetNewCommentText] = useState("");

  function CreateNewComment() {
    if (!props.LoginCheck("comment") || newCommentPosting) {
      return;
    }
    SetNewCommentPosting(true);
    axios({
      method: "post",
      url: Constants.COMMENTS_API_URL + "CreateComment",
      params: {
        articleId: props.basicInfo.articleId,
        userId: props.basicInfo.userId,
        username: props.basicInfo.username,
        userEmail: props.basicInfo.userEmail,
        content: newCommentText,
        isVisible: true,
        parentComment: "parent",
      },
    })
      .then((response) => {
        SetNewCommentText("");
        SetNewCommentPosting(false);
        props.FullRefresh(true);
      })
      .catch((error) => {
        alert("Error posting comment");
        SetNewCommentPosting(false);
        console.log(error);
      });
  }

  function ChangeNewCommentText(event) {
    SetNewCommentText(event.target.value);
  }

  return (
    <div className="newCommentForm">
      <textarea
        id="newParentCommentText"
        value={newCommentText}
        placeholder="Share your thoughts with the group . . ."
        onChange={ChangeNewCommentText}
      />
      <div
        className={
          !newCommentPosting
            ? "newCommentPostButton"
            : "newCommentPostButton postButtonDisabled"
        }
        onClick={newCommentPosting ? null : CreateNewComment}
      >
        <FontAwesomeIcon
          className="newCommentPostButtonIcon"
          icon={faPaperPlane}
        />
        <div className="newCommentPostButtonText">Comment</div>
      </div>
    </div>
  );
}

export default NewCommentForm;

import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faTag,
  faPenNib,
  faSquareShareNodes,
} from "@fortawesome/pro-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { useSiteContext } from "../../../../../Context";

function ArticleHeader(props) {
  const navigate = useNavigate();
  const { ScrollToTop } = useSiteContext();

  function CopyUrl() {
    navigator.clipboard.writeText(window.location.href);
    alert("Copied to clipboard " + window.location.href);
  }

  return (
    <div className="mainContentHeader article">
      <div className="mainContentHeaderTopContainer">
        <h1 className="mainContentHeaderTitle article">
          {props.article.title}
        </h1>
      </div>
      <div className="mainContentHeaderBottomContainer">
        <div className="mainContentHeaderBottomItemGroup">
          <div className="mainContentHeaderBottomItem">
            <div className="mainContentHeaderBottomItemIcon calendar">
              <FontAwesomeIcon icon={faCalendar} />
            </div>
            <div className="mainContentHeaderBottomItemText hiddenOnMobile">
              Published
            </div>
            <div className="mainContentHeaderBottomItemText">
              {props.article.publishDate.substring(2, 10)}
            </div>
          </div>
          <div className="mainContentHeaderBottomItem">
            <div className="mainContentHeaderBottomItemIcon">
              <FontAwesomeIcon icon={faEye} />
            </div>
            <div className="mainContentHeaderBottomItemText">
              {props.article.views}
            </div>
          </div>
        </div>
        <div className="mainContentHeaderBottomItemGroup">
          <div className="mainContentHeaderBottomItem cursorPointer">
            <div className="mainContentHeaderBottomItemIcon red">
              <FontAwesomeIcon icon={faTag} />
            </div>
            <div className="mainContentHeaderBottomItemText red">
              {props.article.categories.map((c, index) => (
                <div
                  className="articleLink"
                  onClick={() => {
                    ScrollToTop();
                    navigate("/articles?category=" + c.id);
                  }}
                  key={c.id}
                >
                  {c.name +
                    (props.article.categories[index + 1] != null ? "," : "")}
                </div>
              ))}
            </div>
          </div>
          <div className="mainContentHeaderBottomItem cursorPointer">
            <div className="mainContentHeaderBottomItemIcon red">
              <FontAwesomeIcon icon={faPenNib} />
            </div>
            <div className="mainContentHeaderBottomItemText red">
              <div
                className="articleLink"
                onClick={() =>
                  navigate(
                    "/articles?author=" + props.article.author.toLowerCase()
                  )
                }
              >
                {props.article.author}
              </div>
            </div>
          </div>
        </div>
        <div className="mainContentHeaderBottomItemGroup">
          <div className="mainContentHeaderBottomItem cursorPointer shareButton">
            {props.article.hidden ? (
              <div className="mainContentHeaderBottomItemText red">
                (This article is hidden)
              </div>
            ) : null}
            <div
              className="mainContentHeaderBottomItemIcon red"
              onClick={CopyUrl}
            >
              <FontAwesomeIcon icon={faSquareShareNodes} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleHeader;

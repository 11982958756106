import { React, useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSquareArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { useSiteContext } from "../../../../Context";
import { relativeTime } from "human-date";
import LoadingIndicator from "../../../../LoadingIndicator";

function Sidebar(props) {
  const [bannerAds, SetBannerAds] = useState([]);
  const [boxAds, SetBoxAds] = useState([]);
  const [articleCount, SetArticleCount] = useState(0);
  const [articles, SetArticles] = useState([]);
  const [boxAdInView, SetBoxAdInView] = useState(false);
  const { MakeParameterItem, GetRandomArticles, GetAds, ScrollToTop } =
    useSiteContext();
  const _ = require("lodash");
  const navigate = useNavigate();

  function CalculateArticleCount() {
    if (props.attachedArticleId === 0 || props.sidebarListHeight === 0) return;
    let count = props.minimumArticleCount;
    if (props.sidebarListHeight > 45) {
      count = Math.floor(props.sidebarListHeight / 90);
      if (count < props.minimumArticleCount) count = props.minimumArticleCount;
    }
    if (props.sidebarListHeight > 10) {
      ObtainRandomArticles(count);
    }
    SetArticleCount(count);
  }

  function GetTextSizeModifierClass(length) {
    if (length > 50) return " smallestText";
    if (length > 35) return " smallerText";
    return "";
  }

  function ObtainRandomArticles(count) {
    let params = [];
    let countToGet = count + 1;
    params.push(MakeParameterItem("page", "1"));
    params.push(MakeParameterItem("limit", countToGet.toString()));
    GetRandomArticles(params).then((response) => {
      SetArticles(response.filter((x) => x.id !== props.attachedArticleId));
    });
  }

  function ShowArticles() {
    let shownArticles = [];
    let countToShow = articleCount;
    if (countToShow < props.minimumArticleCount)
      countToShow = props.minimumArticleCount;
    for (let i = 0; i < countToShow; i++) {
      shownArticles.push(
        <div
          key={i}
          onClick={
            articles[i] != null
              ? (e) => {
                  SetArticles([]);
                  ScrollToTop();
                  navigate("/article/" + articles[i].slug);
                }
              : null
          }
          className="articleSidebarRowItem article"
        >
          {articles[i] != null ? (
            <>
              <div className="articleSidebarRowItemText article">
                <img
                  className="articleSidebarRowItemText image"
                  src={articles[i].image}
                  alt="article"
                />
                <div className="articleSidebarRowItemText articleInfo">
                  <div
                    className={
                      "articleSidebarRowItemText firstRow" +
                      GetTextSizeModifierClass(articles[i].title.length)
                    }
                  >
                    {articles[i].title}
                  </div>
                  <div className="articleSidebarRowItemText secondRow">
                    <FontAwesomeIcon icon={faEye} className="icon" />{" "}
                    <div className="views">{articles[i].views}</div>
                    <div className="date">
                      {relativeTime(articles[i].publishDate)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      );
    }
    return shownArticles;
  }

  function ShowAds(adType) {
    let adSpaces = [];
    let ads = [];
    let count = 0;
    let adClass = "";
    let loading = "";
    switch (adType) {
      case "banner":
        count = props.bannerAdCount;
        adClass = "sidebarBannerAd";
        ads = bannerAds;
        break;
      case "box":
        count = props.boxAdCount;
        adClass = "sidebarBoxAd";
        ads = boxAds;
        break;
    }
    for (let i = 0; i < count; i++) {
      adSpaces[i] = (
        <a
          key={i}
          to={ads[i] != null ? ads[i].fullLinkUrl : void 0}
          target="_blank"
          rel="noopener noreferrer"
          className={"adContainer " + adType}
        >
          <img
            loading={loading}
            className={adClass}
            src={ads[i] != null ? ads[i].fullImagePath : ""}
            alt="ad"
          />
        </a>
      );
    }
    return adSpaces;
  }

  useEffect(() => {
    if (props.attachedArticleId === 0) return;
    GetAds(props.bannerAdCount != null ? props.bannerAdCount : 0, 0).then(
      (response) => {
        SetBannerAds(response.filter((ad) => ad.adTypeId === 2));
      }
    );
  }, [props.attachedArticleId]);

  useEffect(() => {
    if (props.sidebarListHeight > 0) {
      CalculateArticleCount();
    }
  }, [props.sidebarListHeight]);

  useEffect(() => {
    if (boxAdInView) {
      if (props.sidebarListHeight > 10) {
        GetAds(0, props.boxAdCount != null ? props.boxAdCount : 0).then(
          (response) => {
            SetBoxAds(response.filter((ad) => ad.adTypeId === 3));
          }
        );
        return;
      }
      SetBoxAds([]);
    }
  }, [boxAdInView, props.sidebarListHeight]);

  useEffect(() => {
    if (props.boxAdCount != null) {
      let boxAdContainer = document.getElementById("boxAdContainer");
      let boxAdObserver = new IntersectionObserver(
        (entries) => {
          let foundAnIntersection = false;
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              foundAnIntersection = true;
            }
          });
          SetBoxAdInView(foundAnIntersection);
        },
        { threshold: 0.01 }
      );
      boxAdObserver.observe(boxAdContainer);
      return () => {
        boxAdObserver.unobserve(boxAdContainer);
      };
    }
  }, []);

  return (
    <div
      className={
        props.selectedTab === "related"
          ? "contentSidebarContainer selected "
          : "contentSidebarContainer notSelected"
      }
    >
      <div className="contentSidebar article">
        {props.bannerAdCount > 0 ? (
          <div className="articleSidebarRow">
            <div className="articleSidebarRowItemContainer ad">
              {ShowAds("banner")}
            </div>
          </div>
        ) : null}
        <div className="articleSidebarRow" id="sidebarArticleList">
          <div className="articleSidebarRowItemContainer articles">
            <div className="articleSidebarRowItem title">
              <FontAwesomeIcon
                className="articleSidebarRowItemText icon"
                icon={faSquareArrowDown}
              />
              <div className="articleSidebarRowItemText title">Read Next</div>
            </div>
            {articles.length === 0 ? null : ShowArticles()}
          </div>
        </div>
        {props.boxAdCount > 0 ? (
          <div className="articleSidebarRow">
            <div
              id="boxAdContainer"
              className="articleSidebarRowItemContainer ad"
            >
              {ShowAds("box")}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Sidebar;

import React from "react";
import { useSiteContext } from "../Context";
import { Helmet } from "react-helmet-async";
import Header from "./Header/Header";
import MainScreenContent from "./MainScreenContent/MainScreenContent";
import Footer from "./Footer/Footer";
import AddArticleButton from "./AddArticleButton";

function MainScreen() {
  const { IsAdmin, SEOData } = useSiteContext();

  // function IsAdminPage() {
  //   const path = parsePath(window.location.pathname);
  //   const match = matchPath(pathname, {
  //     path: "/admin",
  //     strict: false,
  //   });
  //   return match;
  // }

  return (
    <>
      {SEOData != null ? (
        <Helmet>
          <title>{SEOData.title}</title>
          <meta name="description" content={SEOData.description} />
          <meta name="keywords" content={SEOData.keywords} />
          <meta name="author" content={SEOData.author} />
          <meta name="twitter:card" content={SEOData.twitterCard} />
          <meta name="twitter:site" content={SEOData.twitterSite} />
          <meta name="twitter:creator" content={SEOData.twitterCreator} />
          <meta name="twitter:title" content={SEOData.twitterTitle} />
          <meta
            name="twitter:description"
            content={SEOData.twitterDescription}
          />
          <meta name="twitter:image" content={SEOData.twitterImage} />
          <meta property="og:type" content={SEOData.ogtype} />
          <meta property="og:url" content={SEOData.ogurl} />
          <meta property="og:title" content={SEOData.ogtitle} />
          <meta property="og:description" content={SEOData.ogdescription} />
          <meta property="og:image" content={SEOData.ogimage} />
        </Helmet>
      ) : null}
      <div className="mainScreenContainer">
        <Header />
        {IsAdmin() ? <AddArticleButton /> : null}
        <MainScreenContent />
        <Footer />
      </div>
    </>
  );
}

export default MainScreen;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/pro-solid-svg-icons";
import { useSiteContext } from "../../../Context";
import HeaderDropdown from "../HeaderDropdown";

function Categories() {
  const [showDropdown, SetShowDropdown] = useState(false);
  const { articleCategories, MakeDropdownItem } = useSiteContext();

  function ShowDropdownOnMouse() {
    SetShowDropdown(true);
  }

  function HideDropdownOnMouse() {
    setTimeout(() => {
      SetShowDropdown(false);
    }, 500);
  }

  function DropdownInfo() {
    let dropdownItems = [];
    articleCategories.forEach((category) => {
      dropdownItems.push(
        MakeDropdownItem(
          faCaretRight,
          category.name.toUpperCase(),
          `/articles?category=${category.id}`,
          "blogSite"
        )
      );
    });
    return dropdownItems;
  }

  return (
    <div
      className="headerItemContainer categories hiddenOnMobile"
      onMouseLeave={HideDropdownOnMouse}
    >
      <div
        className="headerItemContainerItem categories"
        onMouseOver={ShowDropdownOnMouse}
      >
        <FontAwesomeIcon className="headerIcon list" icon={faList} />
        <div className="headerItemText">
          CATEGORIES
          <FontAwesomeIcon
            className="headerIcon caretDown"
            icon={faCaretDown}
          />
        </div>
      </div>
      {showDropdown ? (
        <HeaderDropdown
          typeClass={"categories"}
          dropdownItems={DropdownInfo()}
        />
      ) : null}
    </div>
  );
}

export default Categories;

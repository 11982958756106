import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBlind } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as Constants from "../../../Constants";

function AdminShadow(props) {
  function ShadowBanUser() {
    axios({
      method: "post",
      url: Constants.COMMENTS_API_URL + "BanUser",
      params: {
        username: props.username,
        userId: props.userId,
        userEmail: props.adminUserEmail,
        admUserId: props.adminUserId,
        type: "sb",
      },
    })
      .then((response) => {
        alert(props.username + " has been Shadow-Banned!");
        props.FullRefresh(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faBlind}
        id={props.id + "AdminShadow"}
        className="commentAdminDelete"
        data-tooltip-id="commentTooltip"
        data-tooltip-content="Shadowban Commenter"
        onClick={ShadowBanUser}
      />
    </>
  );
}

export default AdminShadow;

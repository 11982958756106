import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { useSiteContext } from "../../../../Context";

function ArticleCard(props) {
  const navigate = useNavigate();
  const { ScrollToTop } = useSiteContext();

  function GenerateHiddenOrNotPublishedClass() {
    let className = "";
    let today = new Date();
    let publishDate = new Date(props.article.publishDate);
    if (props.article.hidden) className = " hidden";
    if (publishDate > today) className = " notPublished";
    return className;
  }

  function DetermineHiddenOrNotPublishedTitle() {
    let today = new Date();
    let title = "";
    let publishDate = new Date(props.article.publishDate);
    if (props.article.hidden) title = "(Hidden) ";
    if (publishDate > today) title = "(Not Published) ";
    return title;
  }

  return (
    <>
      <div
        onClick={() => {
          ScrollToTop();
          navigate("/article/" + props.article.slug);
        }}
        className={
          "mainContentBodyItem articleSection" +
          GenerateHiddenOrNotPublishedClass()
        }
      >
        <img
          className="mainContentBodyItemImage articleCard"
          src={props.article.image}
          alt=""
        />
        <div className="mainContentBodyItemInfoContainer articleCard">
          <div className="mainContentText articleCard">
            {DetermineHiddenOrNotPublishedTitle() + props.article.title}
          </div>
          <div className="mainContentText articleCard categories">
            {props.article.categories.map((c, index) => (
              <div
                onClick={() => navigate("/articles?category=" + c.id)}
                key={c.id}
              >
                {c.name +
                  (props.article.categories[index + 1] != null ? "," : "")}
              </div>
            ))}
          </div>
          <div className="mainContentBodyItemRow articleCard">
            <div className="mainContentIconContainer">
              <FontAwesomeIcon className="icon eye" icon={faEye} />
            </div>
            <div className="mainContentText articleCard dateview">
              {props.article.views}
            </div>
            <div className="mainContentText articleCard dateview">
              {props.article.publishDate.slice(0, 10)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArticleCard;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/pro-regular-svg-icons";
import {
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  function HandleNavigation(path) {
    window.open("https://funker530.com/" + path, "_self");
  }

  return (
    <div className="footerContainer">
      <div className="footerItemContainer">
        <div className="footerItem">
          <div className="footerItemIconContainer copyRight">
            <FontAwesomeIcon className="footerItemIcon" icon={faCopyright} />
          </div>
          <div className="footerItem">
            <div className="footerItemLableText funker">FUNKER</div>
            <div className="footerItemLableText">530</div>
          </div>
        </div>
      </div>
      <div className="footerItemContainer middle">
        <div className="footerItem middle">
          <div
            className="footerItemLable middle"
            onClick={() => HandleNavigation("about/")}
          >
            About
          </div>
          <div
            className="footerItemLable middle"
            onClick={() => HandleNavigation("advertise/")}
          >
            Contact
          </div>
          <div
            className="footerItemLable middle"
            onClick={() => HandleNavigation("privacy-policy/")}
          >
            Privacy Policy
          </div>
          <div
            className="footerItemLable middle"
            onClick={() => HandleNavigation("tos/")}
          >
            TOS
          </div>
          <div
            className="footerItemLable middle"
            onClick={() =>
              window.open("https://www.bunkerbranding.com/pages/funker530")
            }
          >
            Store
          </div>
        </div>
        <div className="footerItem middle">
          <div
            className="footerItemLable middle"
            onClick={() =>
              window.open(
                "https://www.atncorp.com/night-vision-goggles?ref=Funker530Footer"
              )
            }
          >
            Night Vision
          </div>
          <div
            className="footerItemLable middle"
            onClick={() =>
              window.open(
                "https://www.atncorp.com/thermal-scope-thor-hd?ref=Funker530Footer"
              )
            }
          >
            Rifle Optics
          </div>
          <div
            className="footerItemLable middle"
            onClick={() =>
              window.open(
                "https://www.atncorp.com/thermal-binoculars?ref=Funker530Footer"
              )
            }
          >
            Binoculars
          </div>
          <div
            className="footerItemLable middle"
            onClick={() =>
              window.open(
                "https://www.atncorp.com/thermal-scope-thor-hd?ref=Funker530Footer"
              )
            }
          >
            Thermal Imaging
          </div>
        </div>
      </div>
      <div className="footerItemContainer">
        <div className="footerItem">
          <div className="footerItemLable sponsor">POWERED BY</div>
          <div className="footerItemLogos">
            <img
              className="footerLogo"
              src="https://images.funker530.com/images/media/images/footer_sponsor_ar500.original.png"
              alt="AR500 Armor"
              onClick={() =>
                window.open(
                  "https://ar500armor.com/?utm_source=Funker530Footer"
                )
              }
            />
            <img
              className="footerLogo"
              src="https://images.funker530.com/images/media/images/sponsor_2_atn.original.png"
              alt="ATN"
              onClick={() =>
                window.open("https://atncorp.com/?utm_source=Funker530Footer")
              }
            />
          </div>
          <div className="footerItemIconContainer social">
            <FontAwesomeIcon
              className="footerItemIcon"
              icon={faFacebookSquare}
              onClick={() => window.open("https://www.facebook.com/funker530")}
            />
          </div>
          <div className="footerItemIconContainer social">
            <FontAwesomeIcon
              className="footerItemIcon"
              icon={faTwitterSquare}
              onClick={() => window.open("https://twitter.com/funkeractual")}
            />
          </div>
          <div className="footerItemIconContainer social">
            <FontAwesomeIcon
              className="footerItemIcon"
              icon={faYoutubeSquare}
              onClick={() =>
                window.open("https://www.youtube.com/user/FUNKER530")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React, { useState } from "react";
import * as Constants from "../../../Constants";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

function CommentReply(props) {
  const [showReplyContainer, SetShowReplyContainer] = useState(false);
  const [replyText, SetReplyText] = useState("");
  const [replyPosting, SetReplyPosting] = useState(false);

  function ShowReplyContainer() {
    if (replyText.length > 0) {
      SetReplyText("");
    }
    SetShowReplyContainer(!showReplyContainer);
  }

  function ChangeReplyText(event) {
    SetReplyText(event.target.value);
  }

  function ReplyToComment() {
    if (!props.LoginCheck("comment") || replyPosting) {
      return;
    }

    SetReplyPosting(true);
    axios({
      method: "post",
      url: Constants.COMMENTS_API_URL + "CreateComment",
      params: {
        articleId: props.basicInfo.articleId,
        userId: props.basicInfo.userId,
        username: props.basicInfo.username,
        userEmail: props.basicInfo.userEmail,
        content: replyText,
        isVisible: true,
        parentComment: props.comment.id,
      },
    })
      .then((response) => {
        alert("Reply posted succesfully! It will appear shortly");
        ShowReplyContainer();
        SetReplyText("");
        SetReplyPosting(false);
        props.FullRefresh();
      })
      .catch((error) => {
        alert("Error posting reply");
        SetReplyPosting(false);
        console.log(error);
      });
  }

  return (
    <>
      <div className="commentReply" onClick={ShowReplyContainer}>
        {showReplyContainer ? "Cancel" : "Reply"}
      </div>
      {showReplyContainer ? (
        <div className="newCommentReplyForm">
          <textarea
            className="newCommentReplyFormInput"
            type="textarea"
            placeholder={"Reply to " + props.comment.username}
            value={replyText}
            onChange={ChangeReplyText}
          />
          <div
            class={
              !replyPosting
                ? "newCommentPostButton"
                : "newCommentPostButton postButtonDisabled"
            }
          >
            <FontAwesomeIcon
              className="newCommentPostButtonIcon"
              icon={faPenToSquare}
            />
            <div
              className="newCommentPostButtonText"
              onClick={replyPosting ? null : ReplyToComment}
            >
              Reply
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CommentReply;

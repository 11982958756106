import React from "react";
import { useSiteContext } from "../../../../../Context";

function Filter(props) {
  const { articleCategories, authors } = useSiteContext();

  return (
    <div className="filterContainer">
      <div className="filterItem">
        <select
          className="filterSelect"
          onChange={(e) => props.ChangeParams("category", e.target.value)}
          value={
            props.urlParams.category != null &&
            articleCategories != null &&
            articleCategories.filter(
              (c) => props.urlParams.category == c.id
            )[0] != null
              ? articleCategories.filter(
                  (c) => props.urlParams.category == c.id
                )[0].id
              : 0
          }
        >
          <option value={0}>All Categories</option>
          {articleCategories.map((c) => (
            <option value={c.id} key={c.id}>
              {c.name}
            </option>
          ))}
        </select>
      </div>
      <div className="filterItem">
        <select
          className="filterSelect"
          onChange={(e) => props.ChangeParams("author", e.target.value)}
          value={
            props.urlParams.author != null &&
            authors != null &&
            authors.filter(
              (a) =>
                props.urlParams.author.toLowerCase() === a.name.toLowerCase()
            )[0] != null
              ? authors.filter(
                  (a) =>
                    props.urlParams.author.toLowerCase() ===
                    a.name.toLowerCase()
                )[0].name
              : 0
          }
        >
          <option value={0}>All Authors</option>
          {authors != null
            ? authors.map((a) => (
                <option value={a.name} key={a.name}>
                  {a.name}
                </option>
              ))
            : null}
        </select>
      </div>
      <div className="filterItem">
        <select
          className="filterSelect"
          onChange={(e) => props.ChangeParams("earliestToShow", e.target.value)}
          value={
            props.urlParams.earliestToShow != null
              ? props.urlParams.earliestToShow
              : ""
          }
        >
          <option value={""}>All Time</option>
          <option value={"7"}>Last 7 Days</option>
          <option value={"30"}>Last 30 Days</option>
          <option value={"365"}>Last Year</option>
        </select>
      </div>
      <div className="filterItem">
        <div className="filterLabel">Sort By:</div>
        <select
          className="filterSelect"
          onChange={(e) => props.ChangeParams("sorting", e.target.value)}
          value={
            props.urlParams.sorting != null
              ? props.urlParams.sorting
              : "published-desc"
          }
        >
          <option value={"published-desc"}>Latest</option>
          <option value={"published-asc"}>Oldest</option>
          <option value={"views-desc"}>Most Viewed</option>
          <option value={"views-asc"}>Least Viewed</option>
        </select>
      </div>
    </div>
  );
}

export default Filter;

import MainScreen from "./Components/MainScreen/MainScreen";
import Context from "./Components/Context";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Context>
        <BrowserRouter>
          <MainScreen />
        </BrowserRouter>
      </Context>
    </HelmetProvider>
  );
}

export default App;

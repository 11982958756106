import React, { useState, useEffect } from "react";
import Filter from "./Filter/Filter";
import Sidebar from "../Sidebar/Sidebar";
import ArticleCard from "./ArticleCard";
import { useSiteContext } from "../../../../Context";
import { useSearchParams } from "react-router-dom";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ArticlesSection(props) {
  const [articles, SetArticles] = useState([]);
  const { GetArticles, SetSEODataToDefault } = useSiteContext();
  const [params, SetParams] = useSearchParams();
  const urlParams = Object.fromEntries([...params]);

  function GetArticlesFromContext() {
    let getParams = [];
    if (urlParams.author != null) {
      getParams.push({ key: "author", value: urlParams.author });
    }
    if (urlParams.category != null) {
      getParams.push({ key: "category", value: urlParams.category });
    }
    if (urlParams.sorting != null) {
      let sortByField = urlParams.sorting.split("-");
      switch (sortByField[0]) {
        case "published":
          getParams.push({ key: "sortBy", value: "published_date" });
          break;
        case "views":
          getParams.push({ key: "sortBy", value: "views" });
          break;
        default:
          getParams.push({ key: "sortBy", value: "published_date" });
          break;
      }
      switch (sortByField[1]) {
        case "desc":
          getParams.push({ key: "ascending", value: "false" });
          break;
        case "asc":
          getParams.push({ key: "ascending", value: "true" });
          break;
        default:
          getParams.push({ key: "ascending", value: "false" });
          break;
      }
    }
    if (urlParams.earliestToShow != null) {
      getParams.push({
        key: "daysToShow",
        value: urlParams.earliestToShow,
      });
    }
    if (urlParams.search != null && urlParams.search !== "") {
      getParams.push({ key: "search", value: urlParams.search });
    }
    GetArticles(getParams).then((response) => {
      SetArticles(response);
    });
  }

  function ChangeParams(type, value) {
    SetArticles([]);
    let newParams = new URLSearchParams(params);
    if (value == 0) {
      newParams.delete(type);
    } else {
      newParams.set(type, value);
    }
    SetParams(newParams);
  }

  useEffect(() => {
    GetArticlesFromContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlParams.search,
    urlParams.sorting,
    urlParams.author,
    urlParams.category,
    urlParams.earliestToShow,
  ]);

  useEffect(() => {
    SetSEODataToDefault();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mainContent articleSection">
      <div className="mainContentBox articleSection">
        <div className="mainContentHeader articleSection">
          <div className="mainContentHeaderTitle articleSection">
            <div className="titleText">ARTICLES</div>
            {urlParams.search != null && urlParams.search !== "" ? (
              <div className="searchingFor">
                <div className="searchingForLabel">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    onClick={() => ChangeParams("search", 0)}
                  />
                  Search:
                </div>
                <div className="searchingForValue">"{urlParams.search}"</div>
              </div>
            ) : null}
          </div>
          <Filter urlParams={urlParams} ChangeParams={ChangeParams} />
        </div>
        <div className="mainContentBody articleSection">
          {articles.map((a) => (
            <ArticleCard article={a} key={a.id} />
          ))}
        </div>
      </div>
      <Sidebar
        bannerAdCount={1}
        boxAdCount={1}
        articleCount={4}
        attachedArticleId={+("-1." + articles.length)}
        minimumArticleCount={4}
        sidebarListHeight={11}
      />
    </div>
  );
}

export default ArticlesSection;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLineUp } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

function Trending() {
  const navigate = useNavigate();

  function HandleNavigation() {
    navigate("/articles?earliestToShow=7&sorting=views-desc");
  }

  return (
    <div className="headerItemContainer trending hiddenOnMobile">
      <div
        className="headerItemContainerItem trending"
        onClick={HandleNavigation}
      >
        <FontAwesomeIcon
          className="headerIcon chartLineUp"
          icon={faChartLineUp}
        />
        <div className="headerItemText">TRENDING</div>
      </div>
    </div>
  );
}

export default Trending;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import * as Constants from "../../../Constants";

function AdminBan(props) {
  function BanUser() {
    axios({
      method: "post",
      url: Constants.COMMENTS_API_URL + "BanUser",
      params: {
        username: props.username,
        userId: props.userId,
        userEmail: props.adminUserEmail,
        admUserId: props.adminUserId,
        type: "b",
      },
    })
      .then((response) => {
        alert(props.username + " has been Shadow-Banned!");
        props.FullRefresh(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faBan}
        id={props.id + "AdminBan"}
        className="commentAdminDelete"
        data-tooltip-id="commentTooltip"
        data-tooltip-content="Ban Commenter"
        onClick={BanUser}
      />
    </>
  );
}

export default AdminBan;

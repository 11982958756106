import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

function Latest() {
  const navigate = useNavigate();

  function HandleNavigation() {
    navigate("/articles");
  }

  return (
    <div className="headerItemContainer latest hiddenOnMobile">
      <div
        className="headerItemContainerItem latest"
        onClick={HandleNavigation}
      >
        <FontAwesomeIcon className="headerIcon clock" icon={faClock} />
        <div className="headerItemText">LATEST</div>
      </div>
    </div>
  );
}

export default Latest;

import React, { useState } from "react";
import { useSiteContext } from "../../../Context";
import HeaderDropdown from "../HeaderDropdown";
import {
  faUser,
  faThumbsUp,
  faRss,
  faMoon,
  faCog,
  faLock,
  faRectanglePro,
  faArrowAltCircleRight,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Avatar() {
  const [showDropdown, SetShowDropdown] = useState(false);
  const { loggedInUser, IsPremium, MakeDropdownItem } = useSiteContext();

  function ShowDropdownOnMouse() {
    SetShowDropdown(true);
  }

  function HideDropdownOnMouse() {
    setTimeout(() => {
      SetShowDropdown(false);
    }, 500);
  }

  function DropdownInfo() {
    let dropdownItems = [];
    dropdownItems.push(
      MakeDropdownItem(faUser, "PROFILE", "/account/profile", "mainSite")
    );
    dropdownItems.push(
      MakeDropdownItem(faMoon, "TOGGLE DARK", "/account/profile", "mainSite")
    );
    dropdownItems.push(
      MakeDropdownItem(faCog, "EDIT PROFILE", "/accounts/update", "mainSite")
    );
    dropdownItems.push(
      MakeDropdownItem(
        faLock,
        "CHANGE PASSWORD",
        "/accounts/settings",
        "mainSite"
      )
    );
    if (!IsPremium()) {
      dropdownItems.push(
        MakeDropdownItem(
          faRectanglePro,
          "FUNKER530 PREMIUM",
          "/funker530_premium"
        )
      );
    }
    if (loggedInUser != null) {
      dropdownItems.push(
        MakeDropdownItem(
          faArrowAltCircleRight,
          "LOGOUT",
          "accounts/logout?next=" + window.location.href,
          "mainSite"
        )
      );
    } else {
      let path = window.location.pathname.substring(
        window.location.pathname.lastIndexOf("/"),
        window.location.pathname.length
      );
      if (path === "/articles") {
        path = "/";
      }
      dropdownItems.push(
        MakeDropdownItem(
          faArrowAltCircleRight,
          "LOG IN",
          "accounts/blog-verify-logged-in" + path,
          "mainSite"
        )
      );
    }
    return dropdownItems;
  }

  return (
    <>
      <div
        className="headerItemContainer avatar hiddenOnMobile"
        onMouseLeave={HideDropdownOnMouse}
      >
        <div
          className="headerItemContainerItem avatar"
          onMouseOver={ShowDropdownOnMouse}
        >
          {loggedInUser != null ? (
            <img
              className="avatarImage"
              src={
                loggedInUser != null && loggedInUser.avatar !== ""
                  ? "https://images.funker530.com/images/media/" +
                    loggedInUser.avatar
                  : "https://images.funker530.com/images/media/anon.png"
              }
              alt="Avatar"
            />
          ) : (
            <FontAwesomeIcon className="avatarImage" icon={faUser} />
          )}
        </div>
        {showDropdown ? (
          <HeaderDropdown typeClass={"avatar"} dropdownItems={DropdownInfo()} />
        ) : null}
      </div>
    </>
  );
}

export default Avatar;
